import { App } from '../Main.elm'

type VerifiableCredential = {
  docId: string
  issuer: Issuer
  annotations: Annotation[]
  ownerId: string
  firstName: string
  lastName: string
  title: string
  createdAt: string
  completedAt: string
  domain: string
  additionalMsg: string
  dueDate: string
  initializeVectorMerged: string
}

type Annotation = {
  content: string
  type: string
  width: string
  height: string
  x: string
  y: string
  userId: string
  page: string
  fontSize: string
}
type Issuer = {
  documentStore: string
  name: string
  identityProof: IdentityProof
}

type IdentityProof = {
  location: string
  type: string
}

const readJsonFile = (app: App) => async (
  base64File: string,
): Promise<void> => {
  const response = await fetch(base64File).then((result) => result.blob())
  const reader = new FileReader()
  reader.readAsText(response)
  const jsonData = await new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
  if (jsonData == null || typeof jsonData !== 'string') {
    app.ports.returnJsonFileData.send(null)
  } else {
    app.ports.returnJsonFileData.send(createVerifiableCredential(jsonData))
  }
}

export const initialiseJsonReader = (app: App): void => {
  app.ports.readJsonFile.subscribe(readJsonFile(app))
}

const createVerifiableCredential = (
  json: string,
): VerifiableCredential | null => {
  try {
    const jsonData = JSON.parse(json.replace(/'/g, ''))
    return {
      docId: jsonData.docId,
      issuer: {
        documentStore:
          jsonData.issuer == null ? null : jsonData.issuer.documentStore,
        name: jsonData.issuer == null ? null : jsonData.issuer.name,
        identityProof:
          jsonData.issuer == null
            ? { location: null, type: null }
            : {
                location: jsonData.issuer.identityProof.location,
                type: jsonData.issuer.identityProof.type,
              },
      },
      annotations:
        jsonData.annotations == null
          ? null
          : jsonData.annotations.map((annotation: Annotation) => {
              return {
                content: annotation.content,
                type: annotation.type,
                width: annotation.width,
                height: annotation.height,
                x: annotation.x,
                y: annotation.y,
                userId: annotation.userId,
                page: annotation.page,
                fontSize: annotation.fontSize,
              }
            }),
      ownerId: jsonData.ownerId,
      firstName: jsonData.firstName,
      lastName: jsonData.lastName,
      title: jsonData.title,
      createdAt: jsonData.createdAt,
      completedAt: jsonData.completedAt,
      domain: jsonData.domain,
      additionalMsg: jsonData.additionalMsg,
      dueDate: jsonData.dueDate,
      initializeVectorMerged: jsonData.initializeVectorMerged,
    }
  } catch (error) {
    return null
  }
}
