import { App } from '*.elm'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js'
import {
  StorageCanvas,
  FilledDocumentAnnot,
  DocumentAnnotation,
  DocumentStatus,
} from './lib/types'
import {
  initiatePage,
  deletePage,
  fabricCanvasArray,
  encodeAnnot,
  clearFabricCanvasArray,
} from './lib/webviewer'
import { fabric } from 'fabric'
import { drawAnnotation } from './documentAnnotation'

type NewDocument = Readonly<{
  data: string // Base64 data
  ext: 'pdf' | 'doc' | 'docx' | 'pptx'
  password: string | null
  fileName: string
  location: Location
}>
// const scale = 1.6

export type Location = 'Document' | 'MultipleDocument'

type Document = Readonly<{
  data: string // Base64 data
  ext: 'pdf' | 'doc' | 'docx' | 'pptx'
  password: string | null
  fileName: string
  filledAnnots: FilledDocumentAnnot[]
}>

let currentDocPages = 0
let startY = 0
let startX = 0
let startScrollTop = 0
let startScrollLeft = 0
let isAnnotSelected = false
let rotationAlert = false

export const loadNewDocument = (app: App) => async (
  docData: NewDocument,
): Promise<void> => {
  addEventListener('keydown', (event) => {
    if (event.key === 'Delete') {
      app.ports.returnDeleteSelectedAnnotMsg.send({})
    }
  })
  rotationAlert = false
  sessionStorage.clear()
  PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`
  const { data } = docData
  const loadingTask = PDFJS.getDocument(data)
  await loadingTask.promise.then(async function (pdf) {
    const metaData = await pdf.getMetadata()
    const metaDataInfo: any = metaData.info
    console.log(metaData)
    //Put it as any type so that I am able to access the attribute
    const isSigPresent: boolean = metaDataInfo.IsSignaturesPresent
    if (isSigPresent) {
      app.ports.returnNonEditableDocError.send('Error')
      return
    }
    currentDocPages = pdf.numPages
    for (let i = 1; i <= pdf.numPages; i++) {
      await renderPage(i, pdf, app, [])
    }
    const pdfViewer = document.getElementById('pdfViewer')
    if (pdfViewer) {
      pdfViewer.addEventListener('touchstart', function (e) {
        startY = e.touches[0].clientY
        startScrollTop = pdfViewer.scrollTop
        startX = e.touches[0].clientX
        startScrollLeft = pdfViewer.scrollLeft
        pdfViewer.scrollLeft
      })

      pdfViewer.addEventListener('touchmove', function (e) {
        if (isAnnotSelected == false) {
          const deltaY = e.touches[0].clientY - startY
          const scrollTop = startScrollTop - deltaY
          pdfViewer.scrollTop = scrollTop

          const deltaX = e.touches[0].clientX - startX
          const scrollLeft = startScrollLeft - deltaX
          pdfViewer.scrollLeft = scrollLeft

          e.preventDefault() // Prevent default touchmove behavior (scrolling the page)
        }
      })
    }

    app.ports.returnMultipleDocLoadedMsg.send('Loaded')
  })
}

async function renderPage(
  num: number,
  pdfDoc: PDFJS.PDFDocumentProxy,
  app: App,
  annotations: DocumentAnnotation[],
): Promise<void> {
  pdfDoc.getPage(num).then(async function (page) {
    const canvas = await initiatePage(num)
    const context = canvas.getContext('2d')

    const pdfViewer = document
      .getElementById('pdfViewer')
      ?.getBoundingClientRect()

    // Render the page into the `<canvas>` element.
    if (context != null && pdfViewer != null) {
      const scale = canvas.width / pdfViewer.width
      // const scale = pdfViewer.width / canvas.width
      const viewport = page.getViewport({ scale })
      const scaleX = (pdfViewer.width * 0.9) / viewport.width
      const updatedViewport = page.getViewport({ scale: scaleX })
      canvas.height = updatedViewport.height
      const renderContext = {
        canvasContext: context,
        viewport: updatedViewport,
      }
      const renderTask = page.render(renderContext)
      // Wait for rendering to finish
      await renderTask.promise.then(() => {
        const pageViewPort = page.getViewport({ scale: 1 }) // Scale 1 give the original page dimensions
        const background = canvas.toDataURL('image/png')
        const fabricCanvas = new fabric.Canvas(canvas, { selection: false })
        const pageRotation = page.rotate
        if (pageRotation > 0 && !rotationAlert) {
          app.ports.returnRotationAlert.send('Alert')
          rotationAlert = true
        }

        fabricCanvas.setBackgroundImage(
          background,
          fabricCanvas.renderAll.bind(fabricCanvas),
        )
        fabricCanvas.setWidth(pdfViewer.width * 0.9)
        addListenerToCanvas(
          fabricCanvas,
          num,
          app,
          pageViewPort.width,
          pageViewPort.height,
          'Upload',
        )
        if (annotations.length !== 0) {
          annotations.forEach((annot) => {
            drawAnnotation(
              annot,
              {
                canvas: fabricCanvas,
                pageWidth: pageViewPort.width,
                pageHeight: pageViewPort.height,
              },
              'Upload',
              app,
            )
          })
        }
        // fabricCanvas.on('mouse:dblclick', () => {
        //   console.log('Double Click')
        // })

        // fabricCanvas.on('mouse:wheel', (event) => {
        //   if (event.e.ctrlKey) {
        //     const delta = event.e.deltaY
        //     let zoom = fabricCanvas.getZoom() * 0.999 ** delta

        //     if (zoom > 20) zoom = 20
        //     if (zoom < 0.01) zoom = 0.01
        //     fabricCanvas.zoomToPoint(
        //       { x: event.e.offsetX, y: event.e.offsetY },
        //       zoom,
        //     )
        //     event.e.preventDefault()
        //     event.e.stopPropagation()
        //     console.log(event.e.ctrlKey)
        //   }
        // })
        // fabricCanvas.on('object:modified', (event) => {
        //   console.log('object modified', event)
        // })
        // const rect = new fabric.Rect({
        //   left: 0,
        //   top: 0,
        //   width: 597.18 / 0.62,
        //   height: 50 / 0.62,
        //   fill: '#FF454F',
        //   opacity: 0.5,
        //   transparentCorners: true,
        //   borderColor: 'gray',
        //   cornerColor: 'gray',
        //   cornerSize: 5,
        //   data: { type: 'This is square', signer: 'Me' },
        //   // lockMovementX: true,
        //   // lockMovementY: true,
        //   // lockScalingX: true,
        //   // lockScalingY: true,
        // })
        // fabricCanvas.add(rect)
        // fabricCanvas.renderAll()
        // sessionStorage.setItem('test', fabricCanvas.toJSON())
        const fabricCanvasJson = fabricCanvas.toJSON([
          'left',
          'top',
          'width',
          'height',
          'fill',
          'opacity',
          'transparentCorners',
          'borderColor',
          'cornerColor',
          'cornerSize',
          'data',
          'lockMovementX',
          'lockMovementY',
          'lockScalingX',
          'lockScalingY',
        ])

        const storageCanvas: StorageCanvas = {
          pageNum: num,
          canvas: fabricCanvasJson,
          pageHeight: pageViewPort.height,
          pageWidth: pageViewPort.width,
        }
        sessionStorage.setItem(
          `fabricCanvas${num}`,
          JSON.stringify(storageCanvas),
        )
        if (fabricCanvasArray.length < num) {
          for (let i = 0; i < num; i++) {
            if (fabricCanvasArray[i] === undefined) {
              fabricCanvasArray.push(null)
            }
          }
        }
        fabricCanvasArray[num - 1] = fabricCanvas
      })
    }
  })
}

export const switchDocument = (app: App) => async (
  docData: Document,
): Promise<void> => {
  const { data, filledAnnots } = docData
  addEventListener('keydown', (event) => {
    if (event.key === 'Delete') {
      app.ports.returnDeleteSelectedAnnotMsg.send({})
    }
  })
  rotationAlert = false
  sessionStorage.clear()
  PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`
  const loadingTask = PDFJS.getDocument(data)
  let annotList: DocumentAnnotation[] = []
  filledAnnots.forEach((annotData) => {
    annotList = [...annotList, ...annotData.annotations]
  })
  for (let i = 1; i <= currentDocPages; i++) {
    deletePage(i)
  }
  clearFabricCanvasArray()
  await loadingTask.promise.then(async function (pdf) {
    const metaData = await pdf.getMetadata()
    const metaDataInfo: any = metaData.info
    //Put it as any type so that I am able to access the attribute
    const isSigPresent: boolean = metaDataInfo.IsSignaturesPresent
    if (isSigPresent) {
      app.ports.returnNonEditableDocError.send('Error')
      return
    }
    for (let i = 1; i <= pdf.numPages; i++) {
      const annots = annotList.filter((annot) => annot.data.style.page === i)
      await renderPage(i, pdf, app, annots)
    }
    currentDocPages = pdf.numPages
  })
}

function addListenerToCanvas(
  fabricCanvas: fabric.Canvas,
  pageNumber: number,
  app: App,
  pageWidth: number,
  pageHeight: number,
  status: DocumentStatus,
) {
  if (status === 'Upload') {
    fabricCanvas.on('mouse:down', (data) => {
      // const { offsetX, offsetY } = data.e
      // Changing to pointer data because touch event don't have offsetx and offsety
      const x = data.pointer?.x
      const y = data.pointer?.y

      // Note: 0.62 is the magic number determined by Heuristic evaluation
      // Actual pixel to points conversion is 0.75 but there is some offset involved
      if (x != null && y != null) {
        const offsetXPercentage = Math.ceil(x) / fabricCanvas.getWidth()
        const offsetYPercentage = Math.ceil(y) / fabricCanvas.getHeight()

        const pdfOffsetX = pageWidth * offsetXPercentage
        const pdfOffsetY = pageHeight * offsetYPercentage
        const clickData = { x: pdfOffsetX, y: pdfOffsetY, page: pageNumber }

        app.ports.receiveMultipleDocumentClickAnnotData.send(clickData)
      }
      // Trigger the click event
    })
    fabricCanvas.on('text:editing:exited', (event) => {
      if (event.target != null && event.target.type === 'textbox') {
        const encodeData = encodeAnnot(
          event.target,
          pageNumber,
          fabricCanvas,
          pageWidth,
          pageHeight,
          // @ts-ignore
          event.target.text,
          // @ts-ignore
          event.target._textLines == null ? 1 : event.target._textLines.length,
        )
        app.ports.receiveMultipleDocumentAnnotData.send(encodeData)
      }
    })
    fabricCanvas.on('object:modified', (event) => {
      if (event.target == null) {
        return
      }
      const encodeData = encodeAnnot(
        event.target,
        pageNumber,
        fabricCanvas,
        pageWidth,
        pageHeight,
        null,
        // @ts-ignore
        event.target._textLines == null ? 1 : event.target._textLines.length,
      )
      app.ports.receiveMultipleDocumentAnnotData.send(encodeData)

      // // @ts-ignore
      // switch (event.action) {
      //   case 'drag':
      //     return console.log('Drag')
      //   case 'scaleX':
      //     return console.log('scale x')
      //   case 'scaleY':
      //     return console.log('scale y')
      //   case 'scale':
      //     return console.log('scale')
      //   default:
      //     console.log('Other Actions')
      // }
    })

    fabricCanvas.on('selection:created', (event) => {
      // Add ts-ignore as the structure for the selection:created event is different

      app.ports.receiveSelectedAnnotClickData.send({
        page: pageNumber,
        // @ts-ignore
        id: event.selected[0].data.id,
      })
      isAnnotSelected = true
    })
    fabricCanvas.on('selection:updated', (event) => {
      app.ports.receiveSelectedAnnotClickData.send({
        page: pageNumber,
        // @ts-ignore
        id: event.selected[0].data.id,
      })
    })
    fabricCanvas.on('selection:cleared', () => {
      app.ports.receiveDeselecedAnnotClickData.send({})
      isAnnotSelected = false
    })
  }
}
