import { App } from '../Main.elm'
// import { preloadWebviewer } from './PDF/lib/preloader'
// import { unverifiedAnnotPage } from './PDF/unverifiedAnnotPage'
// import { loadNewTemplate } from './PDF/loadNewTemplate'
// import { loadFillTemplate } from './PDF/loadFillTemplate'
// import {
//   loadNewDocument,
//   switchDocument,
//   loadswitchedDocAnnot,
//   exportAsPDF,
// } from './PDF/loadNewDocument'
// import { updateTemplate } from './PDF/updateTemplate'
// import { updateFillTemplate } from './PDF/updateFillTemplate'
// import { updatePlaceholderAnnotation } from './PDF/updatePlaceholderAnnotation'

export function initialisePDFPorts(app: App): void {
  // app.ports.loadNewDocument.subscribe(loadNewDocument(app, PDFTRON_EDITOR_KEY))
  // app.ports.switchNewDocument.subscribe(switchDocument(app, PDFTRON_EDITOR_KEY))
  // app.ports.loadswitchedDocAnnot.subscribe(
  //   loadswitchedDocAnnot(app, PDFTRON_EDITOR_KEY),
  // )
  // app.ports.exportAsPDF.subscribe(exportAsPDF(app, PDFTRON_EDITOR_KEY))
  // app.ports.loadNewTemplate.subscribe(loadNewTemplate(app, PDFTRON_EDITOR_KEY))
  // app.ports.preloadWebviewer.subscribe(preloadWebviewer(PDFTRON_EDITOR_KEY))
  // app.ports.updatePlaceholderAnnotations.subscribe(
  //   updatePlaceholderAnnotation(),
  // )
  // app.ports.updateDocument.subscribe(updateDocument())
  // app.ports.updateTemplate.subscribe(updateTemplate())
  // app.ports.updateFillTemplate.subscribe(updateFillTemplate())
  // app.ports.toUnverifiedAnnotPage.subscribe(unverifiedAnnotPage())
}
