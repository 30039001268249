import dayjs from 'dayjs'
import {
  createUneditableFreeText,
  createEditableFreeText,
  createTextStamp,
  createImageStamp,
} from './lib/annotation'
import { TemplateAnnotation, impossibleCase, CanvasData } from './lib/types'
import { App } from '*.elm'

export function drawAnnotation(
  annotation: TemplateAnnotation,
  canvasData: CanvasData,
  // annotManager: CoreControls.AnnotationManager,
  //   status: DocumentStatus,
  app: App,
): void {
  switch (annotation.kind) {
    case 'Name':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        'Upload',
        app,
        false,
      )
    case 'Date':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          content: dayjs(parseInt(annotation.data.content)).format(
            'DD-MMM-YYYY',
          ),
        },
        false,
        'Upload',
        app,
        false,
      )
    case 'FreeText':
      createEditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        'Upload',
        app,
        false,
      )
      break

    case 'Signature':
      return createImageStamp(
        canvasData,
        annotation.kind,
        annotation.data,
        'Upload',
        app,
      )

    case 'TextField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        'Upload',
        app,
        false,
      )

    case 'DateField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          content: 'Date of signing',
        },
        false,
        'Upload',
        app,
        false,
      )

    case 'SignatureField':
      return createTextStamp(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          style: { ...annotation.data.style, fontSize: '0pt' },
        },
        'Upload',
        app,
      )

    case 'NumberField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        'Upload',
        app,
        false,
      )

    case 'StampField':
      return createTextStamp(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          style: { ...annotation.data.style, fontSize: '0pt' },
        },
        'Upload',
        app,
      )

    case 'EmailField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          content: 'Email',
        },
        false,
        'Upload',
        app,
        false,
      )
    case 'NameField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          content: 'Name',
        },
        false,
        'Upload',
        app,
        false,
      )
    case 'CheckboxField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        true,
        'Upload',
        app,
        false,
      )

    default:
      return impossibleCase(annotation)
  }
}
