import { App } from '../Main.elm'

export function initialiseAffilatePorts(app: App): void {
  app.ports.affliateScript.subscribe(initAffilateScript)
}

const initAffilateScript = (): void => {
  const interval = setInterval(async () => {
    const element = document.getElementById('affilate')
    if (element) {
      clearInterval(interval)
      const affliateScript = document.createElement('script')
      affliateScript.src =
        process.env.ELM_APP_AFFLIATE_LOGIN_REGISTER_PAGE_URL == undefined
          ? 'https://www.example.com' //Placeholder url
          : process.env.ELM_APP_AFFLIATE_LOGIN_REGISTER_PAGE_URL
      affliateScript.type = 'text/javascript'
      affliateScript.defer = true
      element.appendChild(affliateScript)
    }
  }, 200)
}
