import { Style } from './types'
import { fabric } from 'fabric'

type EncodeAnnot = Readonly<{
  id: string
  content: string | null
  style: Style
  status: string
  kind: string
}>

export let fabricCanvasArray: (fabric.Canvas | null)[] = []
export const initiatePage = async (
  pageNumber: number,
): Promise<HTMLCanvasElement> => {
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      const webviewerNode = document.getElementById('pdfViewer')
      if (webviewerNode) {
        const rect = webviewerNode.getBoundingClientRect()
        clearInterval(interval)
        const canvas = document.createElement('CANVAS') as HTMLCanvasElement
        canvas.height = rect.height
        canvas.width = rect.width
        canvas.id = 'canvas' + pageNumber
        const div = document.createElement('div')
        div.id = `canvasContainer${pageNumber}`
        // div.style.paddingLeft = '50%'
        div.style.marginBottom = '10px'
        div.appendChild(canvas)
        webviewerNode.appendChild(div)
        resolve(canvas)
      }
    }, 200)
  })
}

export function clearFabricCanvasArray(): void {
  fabricCanvasArray = []
}

export function deletePage(pageNumber: number): void {
  const canvasElement = document.getElementById(`canvasContainer${pageNumber}`)
  if (
    canvasElement != null &&
    canvasElement instanceof Node &&
    typeof canvasElement === 'object'
  ) {
    canvasElement.remove()
  }
}

export function unload(): void {
  // Removes any of the leftover pdf viewer elements
  fabricCanvasArray = []
  const webviewerNode = document.querySelector('pdfViewer')
  try {
    let pageNumber = 1
    let canvasElement = null
    do {
      canvasElement = document.getElementById(`canvasContainer${pageNumber}`)
      if (
        canvasElement != null &&
        canvasElement instanceof Node &&
        typeof canvasElement === 'object'
      ) {
        canvasElement.remove()
      }
      pageNumber++
    } while (canvasElement != null)
    if (webviewerNode) {
      webviewerNode.remove()
    }
  } catch (error) {
    console.error(error)
  }
}

export function encodeAnnot(
  annot: fabric.Object | null,
  pageNumber: number,
  fabricCanvas: fabric.Canvas,
  pageWidth: number,
  pageHeight: number,
  updatedText: string | null,
  numberOfLines: number,
): EncodeAnnot[] {
  if (
    annot == null ||
    annot.width == null ||
    annot.height == null ||
    annot.left == null ||
    annot.top == null ||
    annot.scaleY == null ||
    annot.scaleX == null
  ) {
    return []
  }
  const pixelWidthPercentage = annot.getScaledWidth() / fabricCanvas.getWidth()
  const pixelHeightPercentage =
    annot.getScaledHeight() / fabricCanvas.getHeight()
  const pixelXPercentage = annot.left / fabricCanvas.getWidth()
  const pixelYPercentage = annot.top / fabricCanvas.getHeight()
  // const pixelFontSizePercentage =
  //   parseInt(annot.data.fontSize) / fabricCanvas.getHeight()
  const pdfAnnotWidth = pageWidth * pixelWidthPercentage
  const pdfAnnotHeight = pageHeight * pixelHeightPercentage
  const pdfAnnotX = pageWidth * pixelXPercentage
  const pdfAnnotY = pageHeight * pixelYPercentage
  // const pdfFontSize = pageHeight * pixelFontSizePercentage
  return [
    {
      id: annot.data.id,
      content: updatedText === null ? annot.data.content : updatedText,
      style: {
        width: pdfAnnotWidth,
        height: pdfAnnotHeight,
        fontSize: annot.data.fontSize.toString(),
        x: pdfAnnotX,
        y: pdfAnnotY,
        page: pageNumber,
      },
      status: annot.data.status,
      kind: annot.data.kind,
    },
  ]
}
