import 'normalize.css'
import 'cropperjs/dist/cropper.css'
import './main.css'
import { Elm } from './Main.elm'
import { initialisePDFPorts } from './Ports/PDF'
import { initialiseSSEPorts } from './Ports/SSE'
import { initialiseUserPorts } from './Ports/User'
import { initialiseCanvasPorts } from './Ports/Signature'
import { initialiseStripePorts } from './Ports/Stripe'
import { initialiseImageEditorPorts } from './Ports/ImageEditor'
import { initialiseAffilatePorts } from './Ports/AffilateCode'
import { initialiseTextSignaturePort } from './Ports/TextSignature'
import { initialiseDelayPort } from './Ports/Delay'
import { initialiseMetaMaskPort } from './Ports/MetaMask'
import { initialiseClipboardPorts } from './Ports/Clipboard'
import { initialiseJsonReader } from './Ports/JsonReader'
import { initialiseAlgorandPort } from './Ports/AlgorandWalletConnect'
import { initialiseAlgorandOptInPort } from './Ports/OptIn'
import { initialisePaymentTransactionPort } from './Ports/PaymentTransaction'
import { initialiseWalletConnectPorts } from './Ports/WalletConnect'
import { initialiseAdsContentPort } from './Ports/Advertisement'
import { initialisePDFPorts2 } from './Ports/newPDF'
import { initialiseReadPDFPort } from './Ports/PdfFileReader'
import { initialiseLanguagePort } from './Ports/LanguageSwitcher'

// Returns main.XXXX.js or null in development
const getLocalJsBundle = () => {
  const scripts = document.getElementsByTagName('script')
  for (let n = 0; n < scripts.length; n++) {
    const src = scripts[n].src || ''
    const matches = src.match(/\/static\/js\/(main\.\S+\.js)/)
    if (matches) return matches[1]
  }

  return null
}

// Initialise Elm
const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    bscScanExplorerUrl: process.env.ELM_APP_BSCSCAN_EXPLORER_URL,
    algorandExplorerUrl: process.env.ELM_APP_ALGORAND_EXPLORER_URL,
    bscScanApiUrl: process.env.ELM_APP_BSCSCAN_API_ENDPOINT,
    bscScanApiKey: process.env.ELM_APP_BSCSCAN_API_KEY,
    bscScanApiTokenAddress: process.env.ELM_APP_BSCSCAN_TOKEN_ADDRESS,
    affliateUrl: process.env.ELM_APP_AFFLIATE_ACTIVATE_PAGE_URL,
    affliateParams: process.env.ELM_APP_AFFLIATE_QUERY_PARAMS,
    passbaseUrl: process.env.ELM_APP_PASSBASE_URL,
    apiUrl: process.env.ELM_APP_API_URL,
    baseHost: process.env.ELM_APP_BASE_HOST,
    webHost: process.env.ELM_APP_APP_DOMAIN,
    staticUrl: process.env.ELM_APP_STATIC_URL,
    defaultLanguage: process.env.ELM_APP_DEFAULT_LANGUAGE,
    user: localStorage.getItem('user') || null,
    localJsBundle: getLocalJsBundle(),
    currentTime: Date.now(),
    timezoneOffset: new Date().getTimezoneOffset() * -1, // fucking messy: https://stackoverflow.com/questions/21102435/why-does-javascript-date-gettimezoneoffset-consider-0500-as-a-positive-off
    selectedBanner: Math.floor(Math.random() * 3) + 1, // Generates a number betweeen 1-3
  },
})

// Initialise all ports
initialiseSSEPorts(app)
initialisePDFPorts(app)
initialiseUserPorts(app)
initialiseCanvasPorts(app)
initialiseStripePorts(app)
initialiseImageEditorPorts(app)
initialiseAffilatePorts(app)
initialiseTextSignaturePort(app)
initialiseDelayPort(app)
initialiseMetaMaskPort(app)
initialiseClipboardPorts(app)
initialiseJsonReader(app)
initialiseAlgorandPort(app)
initialiseAlgorandOptInPort(app)
initialisePaymentTransactionPort(app)
initialiseWalletConnectPorts(app)
initialiseAdsContentPort(app)
initialisePDFPorts2(app)
initialiseReadPDFPort(app)
initialiseLanguagePort(app)

export default app
