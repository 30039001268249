import { loadStripe } from '@stripe/stripe-js'
import { App, Port } from '../Main.elm'

const stripeKey = process.env.ELM_APP_STRIPE_PUBLIC_KEY || ''
const stripePromise = loadStripe(stripeKey)

const redirectToStripe = (app: App, callbackPort: Port) => async (
  sessionId: string,
): Promise<void> => {
  const stripe = await stripePromise
  if (stripe == null) {
    return
  }
  const { error } = await stripe.redirectToCheckout({ sessionId })
  if (error) {
    callbackPort.send('Unable to redirect to stripe, please try again.')
  }
}

export function initialiseStripePorts(app: App): void {
  app.ports.checkoutToStripe.subscribe(
    redirectToStripe(app, app.ports.receiveStripeError),
  )
  app.ports.checkoutToStripeCreateBusiness.subscribe(
    redirectToStripe(app, app.ports.receiveStripeErrorCreateBusiness),
  )
}
