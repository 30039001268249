import { App } from '../Main.elm'
function copy(referralCode: string): void {
  const element = document.createElement('input')
  element.value = referralCode
  document.body.appendChild(element)
  element.select()
  element.setSelectionRange(0, 99999) // For mobile devices
  document.execCommand('copy')
  document.body.removeChild(element) // Remove element from body
  return
}

export function initialiseClipboardPorts(app: App): void {
  app.ports.copyToClipboard.subscribe(copy)
}
