import {
  AnnotTemplateData,
  //   TemplateAnnotation,
  StorageCanvas,
} from './lib/types'
import { App } from '*.elm'
import { drawAnnotation } from './templateAnnotation'
import { fabricCanvasArray } from './lib/webviewer'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js'

export function updateTemplate(
  app: App,
): (annotData: AnnotTemplateData) => void {
  return async function (annotData: AnnotTemplateData): Promise<void> {
    const { annotations, base64FileData, pagesWithDeletedAnnot } = annotData
    let pageNum = 1
    let storageString = sessionStorage.getItem(`fabricCanvas${pageNum}`)
    const canvasArray: StorageCanvas[] = []
    do {
      if (storageString != null) {
        canvasArray.push(JSON.parse(storageString))
        pageNum++
        storageString = sessionStorage.getItem(`fabricCanvas${pageNum}`)
      }
    } while (storageString != null)
    canvasArray.sort((a, b) => a.pageNum - b.pageNum)

    const loadingTask = PDFJS.getDocument(base64FileData)
    // Need to be able to search for all annotation or be able to delete annotation
    await loadingTask.promise.then(async function (pdf) {
      for (let i = 1; i <= pdf.numPages; i++) {
        const annotInPage = annotations.filter(
          (annot) => annot.data.style.page === i,
        )
        if (
          annotInPage.length > 0 ||
          pagesWithDeletedAnnot.some((page) => page === i)
        ) {
          const canvas = canvasArray[i - 1]
          const { pageHeight, pageWidth } = canvas
          const canvasElement = fabricCanvasArray[i - 1]
          if (canvasElement) {
            canvasElement.getObjects().map((obj) => {
              canvasElement.remove(obj)
            })
            annotInPage.forEach((annot) => {
              drawAnnotation(
                annot,
                {
                  canvas: canvasElement,
                  pageHeight,
                  pageWidth,
                },
                app,
              )
            })
          }
        }
      }
    })
  }
}

// async function recreateCanvas(
//   canvasData: StorageCanvas,
//   page: PDFJS.PDFPageProxy,
// ): Promise<fabric.Canvas | null> {
//   const canvasContainerElement = document.getElementById(
//     `canvasContainer${canvasData.pageNum}`,
//   )
//   const canvasElement = document.getElementById(
//     `canvas${canvasData.pageNum}`,
//   ) as HTMLCanvasElement
//   const canvasChild = canvasContainerElement?.lastChild
//   if (canvasContainerElement && canvasChild) {
//     canvasContainerElement.removeChild(canvasChild)
//   }
//   const webviewerNode = document.getElementById('pdfViewer')
//   if (webviewerNode == null) {
//     return null
//   }
//   const rect = webviewerNode.getBoundingClientRect()
//   const canvas = document.createElement('CANVAS') as HTMLCanvasElement
//   canvas.height = canvasElement == null ? rect.height : canvasElement.height
//   canvas.width = canvasElement == null ? rect.width : canvasElement.width
//   canvas.id = `canvas${canvasData.pageNum}`
//   const context = canvas.getContext('2d')
//   if (context == null) {
//     return null
//   }
//   canvasContainerElement?.appendChild(canvas)
//   const pageViewPort = page.getViewport({ scale: 1 })
//   const scaleX = (rect.width * 0.9) / pageViewPort.width
//   const updatedViewport = page.getViewport({ scale: scaleX })
//   canvas.height = updatedViewport.height
//   const renderContext = {
//     canvasContext: context,
//     viewport: updatedViewport,
//   }
//   await page.render(renderContext).promise
//   const background = canvas.toDataURL('image/png')
//   const canvasFabric = new fabric.Canvas(canvas)
//   // eslint-disable-next-line @typescript-eslint/no-empty-function
//   canvasFabric.loadFromJSON(canvasData.canvas, () => { })
//   canvasFabric.setBackgroundImage(
//     background,
//     canvasFabric.renderAll.bind(canvasFabric),
//   )
//   canvasFabric.setWidth(rect.width * 0.9)
//   return canvasFabric
// }
