import {
  DocumentAnnotation,
  impossibleCase,
  DocumentStatus,
  CanvasData,
} from './lib/types'
import {
  createUneditableFreeText,
  createEditableFreeText,
  createImageStamp,
  createTextStamp,
  createImageLoadingStamp,
  createEmptyCheckBox,
  createCheckbox,
} from './lib/annotation'
import dayjs from 'dayjs'
import { App } from '*.elm'

export function drawAnnotation(
  annotation: DocumentAnnotation,
  canvasData: CanvasData,
  // annotManager: CoreControls.AnnotationManager,
  status: DocumentStatus,
  app: App,
): void {
  // const webViewer = getWebViewer()
  // if (webViewer == null) return null
  switch (annotation.kind) {
    case 'Name':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        status,
        app,
        false,
      )
    case 'Date':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          content:
            annotation.data.content === 'Date'
              ? 'Date of Signing' // For unverified annotations
              : dayjs(parseInt(annotation.data.content)).format('DD-MMM-YYYY'),
        },
        false,
        status,
        app,
        false,
      )

    case 'EditableText':
      createEditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        status,
        app,
        false,
      )
      break
    case 'NonEditableText':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        status,
        app,
        false,
      )
    case 'UnsignedSignature':
      return createTextStamp(
        canvasData,
        annotation.kind,
        annotation.data,
        status,
        app,
      )

    case 'SignedSignature':
      return createImageStamp(
        canvasData,
        annotation.kind,
        annotation.data,
        status,
        app,
      )
    case 'UnstampedStamp':
      return createTextStamp(
        canvasData,
        annotation.kind,
        annotation.data,
        status,
        app,
      )
    case 'StampedStamp':
      if (annotation.data.imageData.length === 0) {
        return createImageLoadingStamp(
          canvasData,
          annotation.kind,
          annotation.data,
          status,
          app,
        )
      } else {
        return createImageStamp(
          canvasData,
          annotation.kind,
          annotation.data,
          status,
          app,
        )
      }
    case 'Email':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        status,
        app,
        false,
      )
    case 'PlaceHolderCheckbox':
      return createEmptyCheckBox(
        canvasData,
        annotation.kind,
        annotation.data,
        status,
      )

    case 'Checkbox':
      return createCheckbox(
        canvasData,
        annotation.kind,
        annotation.data,
        status,
        app,
      )
    case 'PlaceHolder':
      return createEmptyCheckBox(
        canvasData,
        annotation.kind,
        annotation.data,
        status,
      )
    default:
      return impossibleCase(annotation)
  }

  // annotManager.addAnnotation(annotation, true) // annotation added by PDFTron are considered imported
  // annotManager.redrawAnnotation(annotation) // Redraw annotation to ensure it is movable
  // return annotation
}

// function create(annotation: DocumentAnnotation, status: DocumentStatus): void {
//   switch (annotation.kind) {
//     case 'Name':
//       return
//     // createUneditableFreeText(
//     //   webViewer,
//     //   annotation.kind,
//     //   annotation.data,
//     //   false,
//     // )
//     case 'Date':
//       return
//     // createUneditableFreeText(
//     //   webViewer,
//     //   annotation.kind,
//     //   {
//     //     ...annotation.data,
//     //     content:
//     //       annotation.data.content === 'Date'
//     //         ? 'Date of Signing' // For unverified annotations
//     //         : dayjs(parseInt(annotation.data.content)).format('DD-MMM-YYYY'),
//     //   },
//     //   false,
//     // )

//     case 'EditableText':
//       return
//     // createEditableFreeText(webViewer, annotation.kind, annotation.data)
//     case 'NonEditableText':
//       return
//     // createEditableFreeText(webViewer, annotation.kind, annotation.data)
//     case 'UnsignedSignature':
//       return
//     // createTextStamp(webViewer, annotation.kind, annotation.data)
//     case 'SignedSignature':
//       return
//     // createImageStamp(webViewer, annotation.kind, annotation.data)
//     case 'UnstampedStamp':
//       return
//     // createTextStamp(webViewer, annotation.kind, annotation.data)
//     case 'StampedStamp':
//       return
//     // if (annotation.data.imageData.length === 0) {
//     //   return createImageLoadingStamp(
//     //     webViewer,
//     //     annotation.kind,
//     //     annotation.data,
//     //   )
//     // } else {
//     //   return createImageStamp(webViewer, annotation.kind, annotation.data)
//     // }
//     case 'Email':
//       return
//     // createUneditableFreeText(
//     //   webViewer,
//     //   annotation.kind,
//     //   annotation.data,
//     //   false,
//     // )
//     case 'PlaceHolderCheckbox':
//       return
//     // createEmptyCheckBox(webViewer, annotation.kind, annotation.data)

//     case 'Checkbox':
//       return
//     // createCheckbox(webViewer, annotation.kind, annotation.data, status)
//     case 'PlaceHolder':
//       return
//     // createEmptyCheckBox(webViewer, annotation.kind, annotation.data)
//     default:
//       return impossibleCase(annotation)
//   }
// }
