import { App } from '../Main.elm'

const displayDashboardAds = () => async (showAds: boolean): Promise<void> => {
  const delayInMilliseconds = 100 //1000 milliseconds = 1sec

  setTimeout(function () {
    if (showAds) {
      //parent element
      const adsElement = document.getElementById('adsContent')

      //child element
      const script1 = document.createElement('script')
      script1.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5127541374506965'
      script1.async = true
      script1.setAttribute('crossorigin', 'anonymous')

      adsElement?.appendChild(script1)

      //child element 2
      const ins = document.createElement('INS')
      ins.className = 'adsbygoogle'
      ins.setAttribute('style', 'display:block')
      ins.setAttribute('data-ad-client', 'ca-pub-5127541374506965')
      ins.setAttribute('data-ad-slot', '4986198174')
      ins.setAttribute('data-ad-format', 'auto')
      ins.setAttribute('data-full-width-responsive', 'true')

      adsElement?.appendChild(ins)

      //child element 3
      const script2 = document.createElement('script')
      script2.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({});`

      adsElement?.appendChild(script2)
    }
  }, delayInMilliseconds)
}

const displaySendDocumentConfirmationAds = () => async (
  showAds: boolean,
): Promise<void> => {
  const delayInMilliseconds = 100 //1000 milliseconds = 1sec

  setTimeout(function () {
    //your code to be executed after 1 second
    if (showAds) {
      const adsElement = document.getElementById('sendDocAdsContent')

      //child element
      const script1 = document.createElement('script')
      script1.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5127541374506965'
      script1.async = true
      script1.setAttribute('crossorigin', 'anonymous')

      adsElement?.appendChild(script1)

      const ins = document.createElement('INS')
      ins.className = 'adsbygoogle'
      ins.setAttribute(
        'style',
        'display:block;min-width:250px;max-width:500px;width:100%;height:100px',
      )
      ins.setAttribute('data-ad-client', 'ca-pub-5127541374506965')
      ins.setAttribute('data-ad-slot', '2040093332')
      // ins.setAttribute('data-ad-format', 'auto')
      // ins.setAttribute('data-full-width-responsive', 'true')

      adsElement?.appendChild(ins)

      //child element 3
      const script2 = document.createElement('script')
      script2.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({});`

      adsElement?.appendChild(script2)
    }
  }, delayInMilliseconds)
}

const displayDocumentListingAds = () => async (
  showAds: boolean,
): Promise<void> => {
  const delayInMilliseconds = 100 //1000 milliseconds = 1sec

  setTimeout(function () {
    if (showAds) {
      const adsElement = document.getElementById('documentListAdsContent')

      if (adsElement && !adsElement.hasChildNodes()) {
        // Create child elements and append them only if the parent element is empty
        //child element
        const script1 = document.createElement('script')
        script1.src =
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5127541374506965'
        script1.async = true
        script1.setAttribute('crossorigin', 'anonymous')

        adsElement.appendChild(script1)

        const ins = document.createElement('INS')
        ins.className = 'adsbygoogle'
        ins.setAttribute(
          'style',
          'display:block;min-width:250px;max-width:1200px;width:100%;height:200px',
        )
        ins.setAttribute('data-ad-client', 'ca-pub-5127541374506965')
        ins.setAttribute('data-ad-slot', '8960724900')
        // ins.setAttribute('data-ad-format', 'auto')
        // ins.setAttribute('data-full-width-responsive', 'true')

        adsElement.appendChild(ins)

        //child element 3
        const script2 = document.createElement('script')
        script2.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({});`

        adsElement.appendChild(script2)
      }

      //add bottom Ad container
      const bottomAdsElement = document.getElementById(
        'documentListAdsContentBottom',
      )

      if (bottomAdsElement && !bottomAdsElement.hasChildNodes()) {
        // Create child elements and append them only if the parent element is empty
        const scriptTag1 = document.createElement('script')
        scriptTag1.src =
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5127541374506965'
        scriptTag1.async = true
        scriptTag1.setAttribute('crossorigin', 'anonymous')

        bottomAdsElement.appendChild(scriptTag1)
        const insTag = document.createElement('INS')
        insTag.className = 'adsbygoogle'
        insTag.setAttribute(
          'style',
          'display:block;min-width:250px;max-width:1200px;width:100%;height:200px',
        )
        insTag.setAttribute('data-ad-client', 'ca-pub-5127541374506965')
        insTag.setAttribute('data-ad-slot', '8960724900')

        bottomAdsElement.appendChild(insTag)

        const scriptTag2 = document.createElement('script')
        scriptTag2.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({});`

        bottomAdsElement.appendChild(scriptTag2)
      }
    }
  }, delayInMilliseconds)
}

const displayViewDocumentMenuAds = () => async (
  showAds: boolean,
): Promise<void> => {
  const delayInMilliseconds = 500
  setTimeout(function () {
    if (showAds) {
      const adsElement = document.getElementById('viewDocAdsContent')

      //child element
      const script1 = document.createElement('script')
      script1.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5127541374506965'
      script1.async = true
      script1.setAttribute('crossorigin', 'anonymous')

      adsElement?.appendChild(script1)

      const ins = document.createElement('INS')
      ins.className = 'adsbygoogle'
      ins.setAttribute('style', 'display:block')
      ins.setAttribute('data-ad-client', 'ca-pub-5127541374506965')
      ins.setAttribute('data-ad-slot', '2012173179')
      ins.setAttribute('data-ad-format', 'auto')
      ins.setAttribute('data-full-width-responsive', 'true')

      adsElement?.appendChild(ins)

      //child element 3
      const script2 = document.createElement('script')
      script2.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({});`

      adsElement?.appendChild(script2)
    }
  }, delayInMilliseconds)
}

export const initialiseAdsContentPort = (app: App): void => {
  app.ports.displayDashboardAdsContent.subscribe(displayDashboardAds())
  app.ports.displaySendDocumentAdsContent.subscribe(
    displaySendDocumentConfirmationAds(),
  )
  app.ports.displayDocumentListingAdsContent.subscribe(
    displayDocumentListingAds(),
  )
  app.ports.displayViewDocumentMenuAdsContent.subscribe(
    displayViewDocumentMenuAds(),
  )
}
