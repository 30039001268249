import { App } from '../Main.elm'

/**
 * Learn about debouncing here
 * https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
 */

const timerIDs: number[] = []

// Trigger on every input
const startDebounceTime = (app: App) => async (
  location: AutoCompleteLocation,
): Promise<void> => {
  timerIDs.forEach((id) => clearTimeout(id))

  timerIDs.length = 0 // Empties the array
  timerIDs.push(
    window.setTimeout(function () {
      switch (location) {
        case 'Document':
          app.ports.triggerDebounce.send('trigger')
          break
        case 'MultipleDocument':
          app.ports.triggerMultipleDocDebounce.send('trigger')
          break
        case 'FillTemplate':
          app.ports.triggerFillTemplateDebounce.send('trigger')
          break
        case 'CreateTemplate':
          app.ports.triggerCreateTemplateDebounce.send('trigger')
          break
      }
    }, 300),
  )
}
// Delay onBlur on the search text box to be able to trigger
// onClick for the addressbook autocomplete list
type AutoCompleteLocation =
  | 'Document'
  | 'MultipleDocument'
  | 'FillTemplate'
  | 'CreateTemplate'

const startBlurTimer = (app: App) => async (
  location: AutoCompleteLocation,
): Promise<void> => {
  window.setTimeout(function () {
    switch (location) {
      case 'Document':
        app.ports.triggerBlur.send('trigger')
        break
      case 'MultipleDocument':
        app.ports.multipleDocTriggerBlur.send('trigger')
        break
      case 'FillTemplate':
        app.ports.fillTemplateTriggerBlur.send('trigger')
        break
      case 'CreateTemplate':
        app.ports.createTemplateTriggerBlur.send('trigger')
        break
    }
  }, 300)
}
export const initialiseDelayPort = (app: App): void => {
  app.ports.startDebounceTime.subscribe(startDebounceTime(app))
  app.ports.startBlurTime.subscribe(startBlurTimer(app))
}
