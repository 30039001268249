import { App } from '../Main.elm'
import detectEthereumProvider from '@metamask/detect-provider'

export function initialiseMetaMaskPort(app: App): void {
  app.ports.getWalletAddress.subscribe(getWalletAddress(app))
}

const getWalletAddress = (app: App) => async (): Promise<void> => {
  // TODO: Find a library for the metamask that have better typing
  // Using any type due to poor function typing
  const provider: any = await detectEthereumProvider({ mustBeMetaMask: true })

  if (provider === window.ethereum) {
    try {
      const confirmationData = await provider.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      })
      const walletID = await provider.request({ method: 'eth_requestAccounts' })
      await provider.request({
        method: 'personal_sign',
        from: walletID[0],
        params: [
          `This is to verify your wallet address: ${walletID[0]}`,
          walletID[0],
        ],
      })
      app.ports.sendWalletAddress.send({
        wallet: walletID[0],
        date: confirmationData[0].date,
      })
    } catch (err) {
      console.log(err)
      app.ports.sendWalletError.send({
        error: `${err.message} Please try again.`,
      })
    }
  } else {
    app.ports.sendWalletError.send({
      error: 'Please install Metamask in order to link your wallet.',
    })
  }
}
