import { App } from '../Main.elm'
import axios from 'axios'
import MyAlgo, { AlgorandTxn } from '@randlabs/myalgo-connect'
export function initialiseAlgorandOptInPort(app: App): void {
  app.ports.optInAsset.subscribe(optInAsset(app))
}
const { ELM_APP_ENV } = process.env
export type AlgoTxn = {
  fee: number
  genesisID: string
  genesisHash: string
  firstRound: number
  lastRound: number
  flatFee: boolean
  amount: number
  from: string
  to: string
  assetIndex: number
}
const myAlgoWallet = new MyAlgo()

const optInAsset = (app: App) => async (Params: {
  userWalletAddress: string
  location: string
}): Promise<void> => {
  try {
    const network = ELM_APP_ENV === 'production' ? 'mainnet' : 'testnet'

    //call external API to opt in ASA
    const apiEndPoint =
      'https://sybgxpig98.execute-api.us-east-2.amazonaws.com/default/TransactionGenerator'

    const externalApiResponse = await axios.post(
      apiEndPoint,
      {
        net: network,
        txnType: 'OPTIN',
        sender: Params.userWalletAddress,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': 'mPFur9wjYv45sLNiFYIsJ7NS0ukwAOgl9Agt8ytc',
        },
      },
    )

    const transaction: AlgoTxn = externalApiResponse.data

    const txn: AlgorandTxn = {
      ...transaction,
      type: 'axfer',
    }
    const signedTxn = await myAlgoWallet.signTransaction(txn)

    const status = externalApiResponse.status

    //Broadcast after optin success
    if (status === 200) {
      const broadcastApiEndPoint =
        'https://qozob1g67j.execute-api.us-east-2.amazonaws.com/default/BroadcastAPI'

      const broadCastApiResponse = await axios.post(
        broadcastApiEndPoint,
        {
          net: network,
          signedTxn: Array.from(signedTxn.blob),
          type: 'OPTIN',
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': 'XW5vqpnCpy9VAmCDKeI052PIkyRhQZ9l95VNY5Qy',
          },
        },
      )
      const response = broadCastApiResponse.data

      //timestamp not need
      switch (Params.location) {
        case 'viewDocument':
          app.ports.sendOptInResultViewDocRoute.send({
            status: broadCastApiResponse.status,
            transactionHash: response.txID,
            blockNumber: response.blockNumber,
          })
          break
        case 'uploadDocument':
          app.ports.sendOptInResultUploadRoute.send({
            status: broadCastApiResponse.status,
            transactionHash: response.txID,
            blockNumber: response.blockNumber,
          })
          break
        case 'FillTemplate':
          app.ports.sendOptInResultFillTemplateRoute.send({
            status: broadCastApiResponse.status,
            transactionHash: response.txID,
            blockNumber: response.blockNumber,
          })
          break
      }
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 500') {
      err.message = 'You need a minimum of 0.2 Algos in your selected wallet'
    }
    switch (Params.location) {
      case 'viewDocument':
        app.ports.sendOptInErrorViewDocRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
      case 'uploadDocument':
        app.ports.sendOptInErrorUploadRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
      case 'FillTemplate':
        app.ports.sendOptInErrorFillTemplateRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
    }
  }
}
