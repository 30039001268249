import { App } from '*.elm'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js'
import { fabric } from 'fabric'
import { StorageCanvas } from './lib/types'
import { initiatePage, fabricCanvasArray, encodeAnnot } from './lib/webviewer'

type NewTemplate = Readonly<{
  data: string // Base64 data
  ext: 'pdf' | 'doc' | 'docx'
  password: string | null
  fileName: string
}>

let startY = 0
let startX = 0
let startScrollTop = 0
let startScrollLeft = 0
let isAnnotSelected = false

export const loadNewTemplate = (app: App) => async (
  docData: NewTemplate,
): Promise<void> => {
  addEventListener('keydown', (event) => {
    if (event.key === 'Delete') {
      app.ports.returnDeleteSelectedTemplateAnnotMsg.send({})
    }
  })
  sessionStorage.clear()
  PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`
  const { data } = docData
  const loadingTask = PDFJS.getDocument(data)

  await loadingTask.promise.then(async function (pdf) {
    for (let i = 1; i <= pdf.numPages; i++) {
      await renderPage(i, pdf, app)
    }

    const pdfViewer = document.getElementById('pdfViewer')
    if (pdfViewer) {
      pdfViewer.addEventListener('touchstart', function (e) {
        startY = e.touches[0].clientY
        startScrollTop = pdfViewer.scrollTop
        startX = e.touches[0].clientX
        startScrollLeft = pdfViewer.scrollLeft
        pdfViewer.scrollLeft
      })

      pdfViewer.addEventListener('touchmove', function (e) {
        if (isAnnotSelected == false) {
          const deltaY = e.touches[0].clientY - startY
          const scrollTop = startScrollTop - deltaY
          pdfViewer.scrollTop = scrollTop

          const deltaX = e.touches[0].clientX - startX
          const scrollLeft = startScrollLeft - deltaX
          pdfViewer.scrollLeft = scrollLeft

          e.preventDefault() // Prevent default touchmove behavior (scrolling the page)
        }
      })
    }

    app.ports.returnTemplateLoadedMsg.send('Loaded')
  })

  async function renderPage(
    num: number,
    pdfDoc: PDFJS.PDFDocumentProxy,
    app: App,
  ) {
    pdfDoc.getPage(num).then(async function (page) {
      const canvas = await initiatePage(num)
      const context = canvas.getContext('2d')

      const pdfViewer = document
        .getElementById('pdfViewer')
        ?.getBoundingClientRect()

      // Render the page into the `<canvas>` element.
      if (context != null && pdfViewer != null) {
        const scale = canvas.width / pdfViewer.width
        // const scale = pdfViewer.width / canvas.width
        const viewport = page.getViewport({ scale })
        const scaleX = (pdfViewer.width * 0.9) / viewport.width
        const updatedViewport = page.getViewport({ scale: scaleX })
        canvas.height = updatedViewport.height
        const renderContext = {
          canvasContext: context,
          viewport: updatedViewport,
        }
        const renderTask = page.render(renderContext)
        // Wait for rendering to finish
        await renderTask.promise.then(() => {
          const pageViewPort = page.getViewport({ scale: 1 }) // Scale 1 give the original page dimensions
          const background = canvas.toDataURL('image/png')
          const fabricCanvas = new fabric.Canvas(canvas, { selection: false })
          fabricCanvas.setBackgroundImage(
            background,
            fabricCanvas.renderAll.bind(fabricCanvas),
          )
          fabricCanvas.setWidth(pdfViewer.width * 0.9)
          addListenerToCanvas(
            fabricCanvas,
            num,
            app,
            pageViewPort.width,
            pageViewPort.height,
          )
          // fabricCanvas.on('mouse:down', (data) => {
          //   const { offsetX, offsetY } = data.e
          //   const offsetXPercentage = offsetX / fabricCanvas.getWidth()
          //   const offsetYPercentage = offsetY / fabricCanvas.getHeight()

          //   const pdfOffsetX = pageViewPort.width * offsetXPercentage
          //   const pdfOffsetY = pageViewPort.height * offsetYPercentage
          //   const clickData = { x: pdfOffsetX, y: pdfOffsetY, page: num }
          //   app.ports.receiveTemplateClickAnnotData.send(clickData)
          //   // Trigger the click event
          // })
          const fabricCanvasJson = fabricCanvas.toJSON([
            'left',
            'top',
            'width',
            'height',
            'fill',
            'opacity',
            'transparentCorners',
            'borderColor',
            'cornerColor',
            'cornerSize',
            'data',
            'lockMovementX',
            'lockMovementY',
            'lockScalingX',
            'lockScalingY',
          ])

          const storageCanvas: StorageCanvas = {
            pageNum: num,
            canvas: fabricCanvasJson,
            pageHeight: pageViewPort.height,
            pageWidth: pageViewPort.width,
          }
          sessionStorage.setItem(
            `fabricCanvas${num}`,
            JSON.stringify(storageCanvas),
          )

          if (fabricCanvasArray.length < num) {
            for (let i = 0; i < num; i++) {
              if (fabricCanvasArray[i] === undefined) {
                fabricCanvasArray.push(null)
              }
            }
          }
          fabricCanvasArray[num - 1] = fabricCanvas
        })
      }
    })
  }

  //   const { data, ext, password, fileName } = docData
  //   let webViewer = getWebViewer()
  //   let pdfDoc = getPdfDoc()
  //   if (webViewer == null) {
  //     webViewer = await initWebViewer(licenseKey)
  //     setWebViewer(webViewer) // Set to webViewer.ts
  //   }
  //   if (ext !== 'pdf') {
  //     pdfDoc = await office2PdfConversion(data, webViewer, licenseKey, ext)
  //     setPdfDoc(pdfDoc)
  //   }
  //   if (pdfDoc == null) {
  //     pdfDoc = await webViewer.PDFNet.PDFDoc.createFromURL(data)
  //     setPdfDoc(pdfDoc) // Set to webViewer.ts
  //   }
  //   const setupWebViewer = (webViewerInstance: WebViewerInstance): void => {
  //     const { docViewer, annotManager } = webViewerInstance
  //     annotManager.setCurrentUser('Owner')
  //     docViewer.on('click', templateClickListener(docViewer, app))
  //     docViewer.on(
  //       'documentLoaded',
  //       exportPDF(
  //         docViewer,
  //         app.ports.returnConvertedPDFTemplate,
  //         app.ports.returnTemplateLoadedMsg,
  //         ext,
  //         fileName,
  //       ),
  //     )
  //     annotManager.on(
  //       'annotationChanged',
  //       templateAnnotChangedListener(app, 'Document'),
  //     )
  //     webViewerInstance.annotationPopup.add(
  //       annotationPopupObjects(
  //         webViewerInstance,
  //         app,
  //         'Document',
  //         templateAnnotChangedListener,
  //       ),
  //     )
  //     webViewerInstance.disableElements(disabledElements)
  //     webViewerInstance.enableElements(['pageNavOverlay'])
  //   }
  //   await unlockAndSetUp(
  //     password,
  //     pdfDoc,
  //     webViewer,
  //     app.ports.receiveTemplateLoadNewEncryptionError,
  //     setupWebViewer,
  //   )
}

// function templateAnnotChangedListener(
//   app: App,
//   location: Location,
// ): (annots: Annotations.Annotation[], action: string) => void {
//   return function (annots: Annotations.Annotation[], action: string): void {
//     if (action === 'modify') {
//       const annotsList = encodeAnnotList(annots)
//       app.ports.receiveTemplateAnnotData.send(annotsList)
//     } else if (action === 'delete') {
//       const annotsList = encodeAnnotList(annots)
//       app.ports.receiveTemplateDeleteAnnotData.send(annotsList)
//     }
//   }
// }

// function templateClickListener(
//   docViewer: CoreControls.DocumentViewer,
//   app: App,
// ): (mouseEvent: MouseEvent) => void {
//   return function (mouseEvent: MouseEvent): void {
//     const data = clickData(docViewer, mouseEvent)
//     app.ports.receiveTemplateClickAnnotData.send(data)
//   }
// }

function addListenerToCanvas(
  fabricCanvas: fabric.Canvas,
  pageNumber: number,
  app: App,
  pageWidth: number,
  pageHeight: number,
) {
  fabricCanvas.on('mouse:down', (data) => {
    // const { offsetX, offsetY } = data.e
    // Changing to pointer data because touch event don't have offsetx and offsety
    const x = data.pointer?.x
    const y = data.pointer?.y

    // Note: 0.62 is the magic number determined by Heuristic evaluation
    // Actual pixel to points conversion is 0.75 but there is some offset involved
    if (x != null && y != null) {
      const offsetXPercentage = Math.ceil(x) / fabricCanvas.getWidth()
      const offsetYPercentage = Math.ceil(y) / fabricCanvas.getHeight()

      const pdfOffsetX = pageWidth * offsetXPercentage
      const pdfOffsetY = pageHeight * offsetYPercentage
      const clickData = { x: pdfOffsetX, y: pdfOffsetY, page: pageNumber }

      app.ports.receiveTemplateClickAnnotData.send(clickData)
    }

    // Trigger the click event
  })
  //   fabricCanvas.on('text:editing:exited', (event) => {
  //     if (event.target != null && event.target.type === 'textbox') {
  //       const encodeData = encodeAnnot(
  //         event.target,
  //         pageNumber,
  //         fabricCanvas,
  //         pageWidth,
  //         pageHeight,
  //         // @ts-ignore
  //         event.target.text,
  //       )
  //       app.ports.receiveTemplateAnnotData.send(encodeData)
  //     }
  //   })
  fabricCanvas.on('object:modified', (event) => {
    if (event.target == null) {
      return
    }
    const encodeData = encodeAnnot(
      event.target,
      pageNumber,
      fabricCanvas,
      pageWidth,
      pageHeight,
      null,
      // @ts-ignore
      event.target._textLines == null ? 1 : event.target._textLines.length,
    )
    app.ports.receiveTemplateDeselecedAnnot.send({})
    app.ports.receiveTemplateAnnotData.send(encodeData)
    // // @ts-ignore
    // switch (event.action) {
    //   case 'drag':
    //     return console.log('Drag')
    //   case 'scaleX':
    //     return console.log('scale x')
    //   case 'scaleY':
    //     return console.log('scale y')
    //   case 'scale':
    //     return console.log('scale')
    //   default:
    //     console.log('Other Actions')
    // }
  })

  fabricCanvas.on('selection:created', (event) => {
    isAnnotSelected = true
    // Add ts-ignore as the structure for the selection:created event is different
    app.ports.receiveSelectedTemplateAnnotClickData.send({
      page: pageNumber,
      // @ts-ignore
      id: event.selected[0].data.id,
    })
  })
  fabricCanvas.on('selection:updated', (event) => {
    app.ports.receiveSelectedTemplateAnnotClickData.send({
      page: pageNumber,
      // @ts-ignore
      id: event.selected[0].data.id,
    })
  })
  fabricCanvas.on('selection:cleared', () => {
    isAnnotSelected = false
    app.ports.receiveTemplateDeselecedAnnot.send({})
  })
}
