// import '@babel/polyfill'
import 'regenerator-runtime/runtime.js' //prevent regenerator runtime undefined error
import MyAlgo from '@randlabs/myalgo-connect'
import { App } from '../Main.elm'
import { apiGetAccountAssets, ChainType } from './Algorand/AlgorandSDK'

export function initialiseAlgorandPort(app: App): void {
  app.ports.getAlgorandWalletAddress.subscribe(connectToMyAlgo(app))
  app.ports.checkOptIn.subscribe(needOptIn(app))
}

const myAlgoWallet = new MyAlgo()
//connectToMyAlgo initiates the connection to myalgoconnect it can only be initiated through a button
const connectToMyAlgo = (app: App) => async (
  location: string,
): Promise<void> => {
  try {
    //initiating connection to their wallet
    const accounts = await myAlgoWallet.connect()

    //maps the addresses over
    const addresses = accounts.map((account) => account.address)
    //console.log(addresses)
    switch (location) {
      case 'viewDocument':
        app.ports.sendAlgorandWalletAddressesViewDocRoute.send({
          wallet: addresses,
        })
        break
      case 'uploadDocument':
        app.ports.sendAlgorandWalletAddressesUploadRoute.send({
          wallet: addresses,
        })
        break
      case 'tokenManagement':
        app.ports.sendAlgorandWalletAddressTokenManagementRoute.send({
          wallet: addresses[0],
        })
        break
      case 'FillTemplate':
        app.ports.sendAlgorandWalletAddressesFillTemplateRoute.send({
          wallet: addresses,
        })
        break
    }
  } catch (err) {
    switch (location) {
      case 'viewDocument':
        app.ports.sendAlgorandWalletErrorViewDocRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
      case 'uploadDocument':
        app.ports.sendAlgorandWalletErrorUploadRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
      case 'tokenManagement':
        app.ports.sendAlgorandWalletErrorTokenManagementRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
      case 'FillTemplate':
        app.ports.sendAlgorandWalletErrorFillTemplateRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
    }
  }
}

const needOptIn = (app: App) => async (Params: {
  selectedWallet: string
  location: string
}): Promise<void> => {
  const signAssetIndex =
    process.env.ELM_APP_ALGORAND_ASA_INDEX == null
      ? 18628677 //TestNet index
      : parseInt(process.env.ELM_APP_ALGORAND_ASA_INDEX)

  const chainType =
    process.env.ELM_APP_ALGORAND_NETWORK == null
      ? ChainType['TestNet']
      : ChainType[
          process.env.ELM_APP_ALGORAND_NETWORK as keyof typeof ChainType
        ]
  const accountData = await apiGetAccountAssets(
    chainType,
    Params.selectedWallet,
  )
  const needOptIn = accountData.every((asset) => asset.id !== signAssetIndex)
  switch (Params.location) {
    case 'viewDocument':
      app.ports.sendOptInStatusViewDocRoute.send({
        needOptIn,
      })
      break
    case 'uploadDocument':
      app.ports.sendOptInStatusUploadRoute.send({
        needOptIn,
      })
      break
    case 'FillTemplate':
      app.ports.sendOptInStatusFillTemplateRoute.send({
        needOptIn,
      })
      break
  }
}
