import {
  FreeText,
  AnnotStatus,
  Style,
  ImageStamp,
  CanvasData,
  DocumentStatus,
  CheckBox,
} from './types'
import { fabric } from 'fabric'
import { App } from '*.elm'
import { encodeAnnot } from './webviewer'

export function createUneditableFreeText(
  canvasData: CanvasData,
  kind: string,
  data: FreeText,
  isCheckBox: boolean,
  status: DocumentStatus,
  app: App,
  isPlaceHolder: boolean,
): void {
  const textBox = createEditableFreeText(
    canvasData,
    kind,
    data,
    status,
    app,
    isPlaceHolder,
  )
  textBox.editable = status === 'Upload' && kind === 'NonEditableText'
  if (isCheckBox) {
    lockAspectRatio(textBox)
  }
}

export function createEditableFreeText(
  canvasData: CanvasData,
  kind: string,
  data: FreeText,
  status: DocumentStatus,
  app: App,
  isPlaceHolder: boolean,
): fabric.Textbox {
  const { canvas, pageHeight, pageWidth } = canvasData
  const { style } = data
  const adjustedX = adjustCoordinateWithinBounds(
    style.x,
    pageWidth,
    style.width,
  )
  const adjustedY = adjustCoordinateWithinBounds(
    style.y,
    pageHeight,
    style.height,
  )
  const lockMovement = status !== 'Upload'
  // 0 means autosizing for PDFtron
  // 0.8 Keeps the text from being too big when autosizing
  // const numberOfLines = data.content.split('\n').length
  const fontSize = isPlaceHolder
    ? kind === 'UnstampedStamp'
      ? data.style.height * 0.35
      : data.style.height * 0.9
    : parseInt(data.style.fontSize)
  // const fontSize = (data.style.height * fontsizeMultiplier) / numberOfLines
  // const fontSize = parseInt(data.style.fontSize) * fontsizeMultiplier
  const textBox = new fabric.Textbox(data.content, {
    top: pointsToPixels(canvasData, adjustedY, 'y'),
    left: pointsToPixels(canvasData, adjustedX, 'x'),
    width: pointsToPixels(canvasData, data.style.width, 'x'),
    textAlign: 'left',
    fontSize: pointsToPixels(canvasData, fontSize, 'y'),
    strokeWidth: 0,
    lockMovementX: lockMovement,
    lockMovementY: lockMovement,
    lockRotation: lockMovement,
    lockScalingFlip: lockMovement,
    lockScalingX: lockMovement,
    lockScalingY: lockMovement,
    lockSkewingX: lockMovement,
    lockSkewingY: lockMovement,
    lockUniScaling: lockMovement,
  })
  // Kept the height external cause when initializing, fontsize is used to determine the initial height
  textBox.height = pointsToPixels(canvasData, data.style.height, 'y')
  if (data.color) {
    const { r, g, b, a } = data.color
    textBox.set('backgroundColor', `rgba(${r} ,${g}, ${b} ,${a})`)
  }
  if (status === 'Signing') {
    textBox.on('selected', (event) => {
      if (event.target != null) {
        const annotsList = encodeAnnot(
          event.target,
          data.style.page,
          canvas,
          pageWidth,
          pageHeight,
          null,
          // @ts-ignore
          event.target._textLines == null ? 1 : event.target._textLines.length,
        )

        app.ports.receiveSelectedAnnotsData.send(annotsList)
      }
    })
  }
  annotDetails(textBox, data.id, kind, data.status, data.style, data.content)
  canvas.add(textBox)
  canvas.renderAll()
  return textBox
}

export function createImageStamp(
  canvasData: CanvasData,
  kind: string,
  data: ImageStamp,
  status: DocumentStatus,
  app: App,
): void {
  const { canvas, pageWidth, pageHeight } = canvasData
  const { id, style } = data
  const adjustedX = adjustCoordinateWithinBounds(
    style.x,
    pageWidth,
    style.width,
  )
  const adjustedY = adjustCoordinateWithinBounds(
    style.y,
    pageHeight,
    style.height,
  )
  const lockMovement = status !== 'Upload'
  fabric.Image.fromURL(
    data.imageData,
    (image) => {
      const formnattedHeight = pointsToPixels(canvasData, style.height, 'y')
      const formattedFontSize = pointsToPixels(
        canvasData,
        parseInt(style.fontSize),
        'y',
      )
      image.set('data', {
        id,
        status: 'Verified', // All signature annotations are verified
        fontSize: formattedFontSize,
        kind,
        content: data.imageData,
      })

      image.scaleToHeight(formnattedHeight)

      lockAspectRatio(image)
      canvas.add(image)
      canvas.renderAll()
      if (kind === 'SignedSignature' && status === 'Signing') {
        const annotsList = encodeAnnot(
          image,
          data.style.page,
          canvas,
          pageWidth,
          pageHeight,
          null,
          1,
        )
        app.ports.receiveUpdatedSignatureWidth.send({
          id: annotsList[0].id,
          width: annotsList[0].style.width,
        })
      }
    },
    {
      top: pointsToPixels(canvasData, adjustedY, 'y'),
      left: pointsToPixels(canvasData, adjustedX, 'x'),
      opacity: 1,
      lockMovementX: lockMovement,
      lockMovementY: lockMovement,
      lockRotation: lockMovement,
      lockScalingFlip: lockMovement,
      lockScalingX: lockMovement,
      lockScalingY: lockMovement,
      lockSkewingX: lockMovement,
      lockSkewingY: lockMovement,
      lockUniScaling: lockMovement,
    },
  )
}

export function createTextStamp(
  canvasData: CanvasData,
  kind: string,
  data: FreeText,
  status: DocumentStatus,
  app: App,
): void {
  createUneditableFreeText(canvasData, kind, data, true, status, app, true)
}

export function createImageLoadingStamp(
  canvasData: CanvasData,
  kind: string,
  data: ImageStamp,
  status: DocumentStatus,
  app: App,
): void {
  const { id, style } = data

  const customTextData: FreeText = {
    id,
    content: 'Loading',
    style,
    status: 'Verified',
    color: { r: 0, g: 0, b: 0, a: 0 },
  }
  const createdText = createEditableFreeText(
    canvasData,
    kind,
    customTextData,
    status,
    app,
    true,
  )
  createdText.editable = false
}

export function createEmptyCheckBox(
  canvasData: CanvasData,
  kind: string,
  data: FreeText,
  docStatus: DocumentStatus,
): void {
  const { canvas, pageHeight, pageWidth } = canvasData
  // createUneditableFreeText(canvas, kind, data, true)
  const { id, status, style, content } = data
  const lockMovement = docStatus !== 'Upload'
  const adjustedX = adjustCoordinateWithinBounds(
    style.x,
    pageWidth,
    style.width,
  )
  const adjustedY = adjustCoordinateWithinBounds(
    style.y,
    pageHeight,
    style.height,
  )
  const rect = new fabric.Rect({
    top: pointsToPixels(canvasData, adjustedY, 'y'),
    left: pointsToPixels(canvasData, adjustedX, 'x'),
    width: pointsToPixels(canvasData, style.width, 'x'),
    height: pointsToPixels(canvasData, style.height, 'y'),
    strokeWidth: 0,
    // backgroundColor: `rgba(${r} ,${g}, ${b} ,${a})`,
    lockMovementX: lockMovement,
    lockMovementY: lockMovement,
    lockRotation: lockMovement,
    lockScalingFlip: lockMovement,
    lockScalingX: lockMovement,
    lockScalingY: lockMovement,
    lockSkewingX: lockMovement,
    lockSkewingY: lockMovement,
    lockUniScaling: lockMovement,
  })
  if (data.color) {
    const { r, g, b, a } = data.color
    const isWhite = r === 255 && g === 255 && b === 255 //owner rgba colour
    const bgColor = isWhite
      ? `rgba(0 ,0,0 ,0.75)` // set to black
      : `rgba(${r} ,${g}, ${b} ,${a})`
    rect.set('backgroundColor', bgColor)
  }
  rect.set('data', {
    id,
    status,
    fontSize: style.fontSize,
    kind,
    content,
  })
  lockAspectRatio(rect)
  canvas.add(rect)
  canvas.renderAll()
}
export function createCheckbox(
  canvasData: CanvasData,
  kind: string,
  data: CheckBox,
  docStatus: DocumentStatus,
  app: App,
): void {
  const { canvas, pageHeight, pageWidth } = canvasData
  const { id, status, style, content } = data
  const lockMovement = true
  const adjustedX = adjustCoordinateWithinBounds(
    style.x,
    pageWidth,
    style.width,
  )
  const adjustedY = adjustCoordinateWithinBounds(
    style.y,
    pageHeight,
    style.height,
  )
  const rect = new fabric.Rect({
    top: pointsToPixels(canvasData, adjustedY, 'y'),
    left: pointsToPixels(canvasData, adjustedX, 'x'),
    width: pointsToPixels(canvasData, style.width, 'x'),
    height: pointsToPixels(canvasData, style.height, 'y'),
    fill: `rgba(${255} ,${255}, ${255} ,${1})`,
    borderColor: 'gray',
    cornerColor: 'gray',
    cornerSize: 5,
    backgroundColor: 'black',
    lockMovementX: lockMovement,
    lockMovementY: lockMovement,
    lockRotation: lockMovement,
    lockScalingFlip: lockMovement,
    lockScalingX: lockMovement,
    lockScalingY: lockMovement,
    lockSkewingX: lockMovement,
    lockSkewingY: lockMovement,
    lockUniScaling: lockMovement,
  })
  rect.on('mousedown', (event) => {
    if (event.target) {
      const checkboxValue = content === 'Unchecked' ? 'Checked' : 'Unchecked'
      const annotsList = encodeAnnot(
        event.target,
        data.style.page,
        canvas,
        pageWidth,
        pageHeight,
        checkboxValue,
        1,
      )
      app.ports.receiveSelectedAnnotsData.send(annotsList)
    }
  })
  if (content === 'Checked') {
    fabric.Image.fromURL(
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/images/tick.png'
        : `https://${process.env.ELM_APP_APP_DOMAIN}/images/tick.png`,
      function (img) {
        img.hasControls = false
        const formnattedHeight = pointsToPixels(canvasData, style.height, 'y')
        const formattedWidth = pointsToPixels(canvasData, style.width, 'x')

        img.scaleToHeight(formnattedHeight)
        img.scaleToWidth(formattedWidth)
        // image.width = formattedWidth
        // image.height = formnattedHeight
        img.on('mousedown', (event) => {
          if (event.target != null) {
            const annotsList = encodeAnnot(
              rect,
              data.style.page,
              canvas,
              pageWidth,
              pageHeight,
              'Unchecked',
              1,
            )
            app.ports.receiveSelectedAnnotsData.send(annotsList)
          }
        })
        img.set('data', {
          id,
          status,
          fontSize: style.fontSize,
          kind,
          content,
        })
        lockAspectRatio(img)
        canvas.add(img)
        canvas.renderAll()
      },
      {
        top: pointsToPixels(canvasData, adjustedY, 'y'),
        left: pointsToPixels(canvasData, adjustedX, 'x'),
        lockMovementX: lockMovement,
        lockMovementY: lockMovement,
        lockRotation: lockMovement,
        lockScalingFlip: lockMovement,
        lockScalingX: lockMovement,
        lockScalingY: lockMovement,
        lockSkewingX: lockMovement,
        lockSkewingY: lockMovement,
        lockUniScaling: lockMovement,
      },
    )
  }

  rect.set('data', {
    id,
    status,
    fontSize: style.fontSize,
    kind,
    content,
  })
  lockAspectRatio(rect)
  canvas.add(rect)
  canvas.renderAll()
}

function lockAspectRatio(
  annot: fabric.Textbox | fabric.Image | fabric.Rect,
): void {
  annot.setControlsVisibility({
    ml: false,
    mr: false,
    mb: false,
    mt: false,
    mtr: false,
  })
}

function annotDetails(
  annot: fabric.Textbox,
  id: string, // uniquely identify annotation
  kind: string,
  status: AnnotStatus, // Determine if the annotation is verified
  style: Style,
  content: string,
): void {
  const data = { id, status, fontSize: style.fontSize, kind, content }
  annot.data = data
}

function pointsToPixels(
  canvasData: CanvasData,
  value: number,
  type: 'x' | 'y', // Reflects thjer type of coordinate/dimension
) {
  const percentage =
    value / (type === 'x' ? canvasData.pageWidth : canvasData.pageHeight)
  return (
    percentage *
    (type === 'x'
      ? canvasData.canvas.getWidth()
      : canvasData.canvas.getHeight())
  )
}

function adjustCoordinateWithinBounds(
  value: number,
  pdfDimnesion: number,
  annotDimension: number,
) {
  const maxValue = pdfDimnesion - annotDimension
  if (value < 0) {
    return 0
  } else if (value > maxValue) {
    return maxValue
  } else {
    return value
  }
}
