import { App } from '../Main.elm'
import Cropper from 'cropperjs'

let state: State | null = null

type State = {
  cropper: Cropper
  config: Config
}

type Action = 'ZoomIn' | 'ZoomOut' | 'RotateCW' | 'RotateCCW'
type Config = {
  cropWidth: number
  cropHeight: number
}

function loadImageEditor(config: Config): void {
  const interval = setInterval(async () => {
    const image = <HTMLImageElement>(
      document.getElementById('ImageEditorImageID')
    )
    if (image == null) {
      return
    }

    clearInterval(interval)
    const cropper = new Cropper(image, {
      dragMode: 'move',
      aspectRatio: config.cropWidth / config.cropHeight,
      cropBoxMovable: false,
      cropBoxResizable: false,
      minCropBoxWidth: config.cropWidth,
      minCropBoxHeight: config.cropHeight,
    })

    state = { cropper, config }
  }, 200)
}

function updateImageEditor(action: Action): void {
  if (state == null) return

  switch (action) {
    case 'ZoomIn':
      state.cropper.zoom(0.1)
      return
    case 'ZoomOut':
      state.cropper.zoom(-0.1)
      return
    case 'RotateCW':
      state.cropper.rotate(90)
      return
    case 'RotateCCW':
      state.cropper.rotate(-90)
      return
    default:
      console.error(`Invalid action: ${action}`)
  }
}

function getImageDataAndDestroy(app: App): () => void {
  return () => {
    if (state == null) return
    const canvas = state.cropper.getCroppedCanvas({
      width: state.config.cropWidth,
      height: state.config.cropHeight,
    })

    if (canvas != null) {
      // Streaming large data directly to elm port will block UI thread
      // We convert it into a local file with a local url reference
      canvas.toBlob((blob) => {
        if (blob == null) return
        const file = new File([blob], 'imageEditorEditedImageFile', {
          type: blob.type,
        })
        const localUrl = URL.createObjectURL(blob)

        if (state != null) state.cropper.destroy()
        state = null
        app.ports.receiveImageEditorData.send({ localUrl, file })
      })
    }
  }
}

export function initialiseImageEditorPorts(app: App): void {
  app.ports.loadImageEditor.subscribe(loadImageEditor)
  app.ports.updateImageEditor.subscribe(updateImageEditor)
  app.ports.getImageEditorDataAndDestroy.subscribe(getImageDataAndDestroy(app))
}
