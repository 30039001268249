import { App } from '../Main.elm'
import sha from 'sha.js'

export function initialiseReadPDFPort(app: App): void {
  app.ports.readPDFFile.subscribe(readPDFFile(app))
}

const readPDFFile = (app: App) => async (base64File: string): Promise<void> => {
  // console.log(base64File)
  const removedHeaderB64Data = base64File.split(',')[1]
  console.log(removedHeaderB64Data)

  const originalFileBuffer = Buffer.from(removedHeaderB64Data, 'base64') //convert b64 to buffer

  const checksumSha256 = hashSha256(originalFileBuffer as Buffer)
  console.log(checksumSha256)

  if (checksumSha256 == null || typeof checksumSha256 !== 'string') {
    app.ports.returnFileChecksum.send(null)
    console.log('fail')
  } else {
    console.log('success')
    app.ports.returnFileChecksum.send({ checksum: checksumSha256 })
  }
}

function hashSha256(data: string | Buffer): string {
  return `0x${new sha.sha256().update(data).digest('hex')}`
}
