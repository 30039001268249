import dayjs from 'dayjs'
import {
  createUneditableFreeText,
  createEditableFreeText,
  createTextStamp,
  createImageStamp,
  createEmptyCheckBox,
} from './lib/annotation'
import { FillTemplateAnnotation, impossibleCase, CanvasData } from './lib/types'
import { App } from '*.elm'

export function drawAnnotation(
  annotation: FillTemplateAnnotation,
  //   annotManager: CoreControls.AnnotationManager,
  canvasData: CanvasData,
  app: App,
): void {
  switch (annotation.kind) {
    case 'Name':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        'Completed',
        app,
        false,
      )
    case 'Date':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          content: dayjs(parseInt(annotation.data.content)).format(
            'DD-MMM-YYYY',
          ),
        },
        false,
        'Completed',
        app,
        false,
      )
    case 'FreeText':
      createEditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        'Completed',
        app,
        false,
      )
      break

    case 'Signature':
      return createImageStamp(
        canvasData,
        annotation.kind,
        annotation.data,
        'Completed',
        app,
      )

    case 'TextField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        'Completed',
        app,
        false,
      )

    case 'DateField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          content: 'Date of signing',
        },
        false,
        'Completed',
        app,
        false,
      )

    case 'UnsignedSignatureField':
      return createTextStamp(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          style: { ...annotation.data.style, fontSize: '0pt' },
        },
        'Completed',
        app,
      )

    case 'SignedSignatureField':
      return createImageStamp(
        canvasData,
        annotation.kind,
        annotation.data,
        'Completed',
        app,
      )

    case 'NumberField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        'Completed',
        app,
        false,
      )

    case 'StampField':
      return createTextStamp(
        canvasData,
        annotation.kind,
        {
          ...annotation.data,
          style: { ...annotation.data.style, fontSize: '0pt' },
        },
        'Completed',
        app,
      )
    case 'EmailField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        'Completed',
        app,
        false,
      )
    case 'NameField':
      return createUneditableFreeText(
        canvasData,
        annotation.kind,
        annotation.data,
        false,
        'Completed',
        app,
        false,
      )
    case 'CheckboxField':
      return createEmptyCheckBox(
        canvasData,
        annotation.kind,
        annotation.data,
        'Completed',
      )

    default:
      return impossibleCase(annotation)
  }
  //   const webViewer = getWebViewer()
  //   if (webViewer == null) return null

  //   const annotation = create(webViewer, annot)
  //   annotManager.addAnnotation(annotation, true) // annotation added by PDFTron are considered imported
  //   annotManager.redrawAnnotation(annotation) // Redraw annotation to ensure it is movable
  //   return annotation
}
