import { App } from '../Main.elm'
import { loadNewDocument, switchDocument } from './newPDFViewer/loadNewDocument'
import { unload } from './newPDFViewer/lib/webviewer'
import { updateDocument } from './newPDFViewer/updateDocument'
import { loadDocument } from './newPDFViewer/loadDocument'
import { loadNewTemplate } from './newPDFViewer/loadNewTemplate'
import { updateTemplate } from './newPDFViewer/updateTemplate'
import { loadFillTemplate } from './newPDFViewer/loadFillTemplate'

export function initialisePDFPorts2(app: App): void {
  app.ports.loadNewDocument2.subscribe(loadNewDocument(app))
  app.ports.closeDocument2.subscribe(unload)
  app.ports.updateDocument.subscribe(updateDocument(app))
  app.ports.loadDocument.subscribe(loadDocument(app))
  app.ports.switchNewDocument.subscribe(switchDocument(app))
  // Templates
  app.ports.loadNewTemplate.subscribe(loadNewTemplate(app))
  app.ports.updateTemplate.subscribe(updateTemplate(app))
  app.ports.loadFillTemplate.subscribe(loadFillTemplate(app))
}
