import { App } from '../Main.elm'
// import fs from 'fs'
// import path from 'path'
import * as englishLanguageData from '../../src/LanguageFiles/english.json'
import * as dutchLanguageData from '../../src/LanguageFiles/dutch.json'

export function initialiseLanguagePort(app: App): void {
  app.ports.languageSwitcher.subscribe(languageSwitcher(app))
}

const languageSwitcher = (app: App) => async (
  language: string,
): Promise<void> => {
  switch (language) {
    case 'English':
      app.ports.sendLanguageData.send({
        location: englishLanguageData.location,
      })
      break
    case 'Dutch':
      app.ports.sendLanguageData.send({
        location: dutchLanguageData.location,
      })
      break
    default:
      app.ports.sendLanguageData.send({
        location: englishLanguageData.location,
      })
  }
}
