import { App } from '../Main.elm'
import axios from 'axios'
import MyAlgo, { AlgorandTxn } from '@randlabs/myalgo-connect'
import { AlgoTxn } from './OptIn'

export function initialisePaymentTransactionPort(app: App): void {
  app.ports.paymentTransaction.subscribe(paymentTransaction(app))
}

const myAlgoWallet = new MyAlgo()
const { ELM_APP_ENV } = process.env

//payment for completed docunments
const paymentTransaction = (app: App) => async (params: {
  userWalletAddress: string
  checksum: string
  recipients: string[]
  ownerId: string
  docId: string
  route: string
  noOfDocuments: number
  isCompleted: boolean
}): Promise<void> => {
  try {
    const network = ELM_APP_ENV === 'production' ? 'mainnet' : 'testnet'

    //call external API for payment
    const apiEndPoint =
      'https://sybgxpig98.execute-api.us-east-2.amazonaws.com/default/TransactionGenerator'

    //NOTARIZATION for completed document route
    //PAYMENT for upload document route (double transaction)
    const transactionType =
      params.isCompleted === true ? 'NOTARIZATION' : 'PAYMENT'

    const completedDocPayload = {
      net: network,
      txnType: transactionType,
      sender: params.userWalletAddress,
      checksum: params.checksum,
      recipients: params.recipients,
      ownerId: params.ownerId,
      id: params.docId,
      noOfDocuments: params.noOfDocuments,
    }

    const uploadDocPayload = {
      net: network,
      txnType: transactionType,
      sender: params.userWalletAddress,
      noOfDocuments: params.noOfDocuments,
    }

    const requestBody =
      params.isCompleted === true ? completedDocPayload : uploadDocPayload

    const externalApiResponse = await axios.post(apiEndPoint, requestBody, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': 'mPFur9wjYv45sLNiFYIsJ7NS0ukwAOgl9Agt8ytc',
      },
    })

    const transaction: AlgoTxn = externalApiResponse.data
    //pop window
    const txn: AlgorandTxn = {
      ...transaction,
      type: 'axfer',
    }
    const signedTxn = await myAlgoWallet.signTransaction(txn)

    //Broadcast to blockchain after payment success
    if (externalApiResponse.status === 200) {
      const broadcastApiEndPoint =
        'https://qozob1g67j.execute-api.us-east-2.amazonaws.com/default/BroadcastAPI'

      const broadCastApiResponse = await axios.post(
        broadcastApiEndPoint,
        {
          net: network,
          signedTxn: Array.from(signedTxn.blob),
          type: 'NOTARIZATION',
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': 'XW5vqpnCpy9VAmCDKeI052PIkyRhQZ9l95VNY5Qy',
          },
        },
      )
      const response = broadCastApiResponse.data

      switch (params.route) {
        case 'viewDocument':
          app.ports.sendNotarisationDetailsViewDocRoute.send({
            transactionHash: response.txID,
            blockNumber: response.blockNumber,
            notarisationTimeStamp: response.timeStamp,
          })
          break
        case 'uploadDocument':
          app.ports.sendNotarisationDetailsUploadRoute.send({
            transactionHash: response.txID,
            blockNumber: response.blockNumber,
            notarisationTimeStamp: response.timeStamp,
          })
          break
        case 'FillTemplate':
          app.ports.sendNotarisationDetailsFillTemplateRoute.send({
            transactionHash: response.txID,
            blockNumber: response.blockNumber,
            notarisationTimeStamp: response.timeStamp,
          })
          break
      }
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 500') {
      err.message = `This transaction requires 5 SIGN points per document`
    }

    switch (params.route) {
      case 'viewDocument':
        app.ports.sendPaymentTransactionErrorViewDocRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
      case 'uploadDocument':
        app.ports.sendPaymentTransactionErrorUploadRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
      case 'FillTemplate':
        app.ports.sendPaymentTransactionErrorFillTemplateRoute.send({
          error: `${err.message}, Please try again.`,
        })
        break
    }
  }
}
