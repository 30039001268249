import { App } from '../Main.elm'

type SSEListener = (params: SSEListenerParams) => void
type SSEListenerParams = { apiEndpoint: string; jwt: string }

let sse: EventSource | null = null
let elmWantsSSE = false // Used to resolve retrying SSE while Elm issue close command

function startServerEvents(app: App): SSEListener {
  const send = app.ports.onServerEvents.send

  const listenToSSE: SSEListener = ({ apiEndpoint, jwt }) => {
    elmWantsSSE = true
    sse = new EventSource(`${apiEndpoint}?jwt=${jwt}`)

    if (sse == null) {
      // SSE is not support
      send({ event: 'NotSupported' })
    } else {
      sse.onopen = () => {
        send({ event: 'Opened' })
      }

      sse.onmessage = (e: { data: string }) => {
        try {
          const data = JSON.parse(e.data)
          if (data.error == null) {
            send({ event: 'Message', data })
          } else {
            send({ event: 'Error', error: data.error })
          }
        } catch (error) {
          send({
            event: 'Error',
            error: { code: 'InvalidJSON', message: error.message },
          })
        }
      }

      sse.onerror = () => {
        // no usable info from the error object
        // so we just send NetworkError event
        send({
          event: 'Error',
          error: { code: 'NetworkError', message: 'Unable to connect.' },
        })

        if (sse != null) sse.close()
        sse = null

        // Retry after 5 seconds
        setTimeout(() => {
          if (elmWantsSSE) return listenToSSE({ apiEndpoint, jwt })
        }, 5000)
      }
    }
  }

  return listenToSSE
}

function closeServerEvents(): void {
  elmWantsSSE = false
  if (sse != null) {
    sse.close()
    sse = null
  }
}

export function initialiseSSEPorts(app: App): void {
  app.ports.startServerEvents.subscribe(startServerEvents(app))
  app.ports.closeServerEvents.subscribe(closeServerEvents)
}
