import { App } from '../Main.elm'

function store(userJson: string): void {
  return localStorage.setItem('user', userJson)
}

function remove(): void {
  return localStorage.removeItem('user')
}

export function initialiseUserPorts(app: App): void {
  app.ports.storeUser.subscribe(store)
  app.ports.deleteUser.subscribe(remove)
}
