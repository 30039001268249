import { App } from '../Main.elm'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import { formatJsonRpcRequest } from '@json-rpc-tools/utils'
import {
  ChainType,
  apiGetTxnParams,
  signTxnWithTestAccount,
  apiGetAccountAssets,
  submitSignedTransaction,
} from './Algorand/AlgorandSDK'
import algosdk from 'algosdk'

export function initialiseWalletConnectPorts(app: App): void {
  app.ports.walletConnectInit.subscribe(walletConnectInit(app))
  app.ports.walletConnectoptIn.subscribe(walletConnectoptIn(app))
  app.ports.getWalletAmount.subscribe(getWalletAmount(app))
  app.ports.walletConnectNotarisePayment.subscribe(
    walletConnectNotarisePayment(app),
  )
  app.ports.walletConnectKillSession.subscribe(killSession())
}
interface IWalletTransaction {
  /**
   * Base64 encoding of the canonical msgpack encoding of a
   * Transaction.
   */
  txn: string

  /**
   * Optional authorized address used to sign the transaction when
   * the account is rekeyed. Also called the signor/sgnr.
   */
  authAddr?: string

  /**
   * Optional multisig metadata used to sign the transaction
   */
  msig?: IMultisigMetadata

  /**
   * Optional list of addresses that must sign the transactions
   */
  signers?: string[]

  /**
   * Optional message explaining the reason of the transaction
   */
  message?: string
}
interface IMultisigMetadata {
  /**
   * Multisig version.
   */
  version: number

  /**
   * Multisig threshold value. Authorization requires a subset of
   * signatures, equal to or greater than the threshold value.
   */
  threshold: number

  /**
   * List of Algorand addresses of possible signers for this
   * multisig. Order is important.
   */
  addrs: string[]
}

type SignTxnParams = [IWalletTransaction[], ISignTxnOpts?]

interface ISignTxnOpts {
  /**
   * Optional message explaining the reason of the group of
   * transactions.
   */
  message?: string

  // other options may be present, but are not standard
}
interface IScenarioTxn {
  txn: algosdk.Transaction
  signers?: string[]
  authAddr?: string
  message?: string
}

type Location = 'Points' | 'ViewDoc' | 'UploadDoc' | 'FillTemplate'

type ScenarioReturnType = IScenarioTxn[][]

// Scenario type is a function that takes in the chain type and the participating address and returns a promise which contains the transaction details
type Scenario = (
  chain: ChainType,
  address: string,
  data: NotarizationDetails | null,
) => Promise<ScenarioReturnType>

type NotarizationDetails = {
  checksum: string | null
  docId: string
  ownerId: string
  recipients: string[]
}
// This is a TEST ASA index that is stored in the blockchain TODO replace it with SIGN's Asset ID 266846137 (MainNet)
// const signAssetIndex = 21015836
// const signAssetIndex = 18628677
const signAssetIndex =
  process.env.ELM_APP_ALGORAND_ASA_INDEX == null
    ? 18628677 //TestNet index
    : parseInt(process.env.ELM_APP_ALGORAND_ASA_INDEX)

const chainType =
  process.env.ELM_APP_ALGORAND_NETWORK == null
    ? ChainType['TestNet']
    : ChainType[process.env.ELM_APP_ALGORAND_NETWORK as keyof typeof ChainType]

const killSession = () => async () => {
  const bridge = 'https://bridge.walletconnect.org'
  // create new connector and flashes QR if not connected
  const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal })
  if (connector.connected) {
    await connector.killSession()
  }
}

const getWalletAmount = (app: App) => async (
  address: string,
): Promise<void> => {
  const accountData = await apiGetAccountAssets(chainType, address)
  const signNetAsa = accountData.filter((asset) => asset.id === signAssetIndex)
  const amount =
    signNetAsa.length === 0
      ? '0 SIGN'
      : (signNetAsa[0].amount / BigInt(10000000)).toString() + ' SIGN'
  app.ports.returnWalletAmount.send(amount)
}

const walletConnectNotarisePayment = (app: App) => async (data: {
  address: string
  notarizationDetails: NotarizationDetails
  isNewInit: boolean
  location: Location
  lastPayment: boolean
}): Promise<void> => {
  const {
    address,
    notarizationDetails,
    isNewInit,
    location,
    lastPayment,
  } = data
  const bridge = 'https://bridge.walletconnect.org'
  // create new connector and flashes QR if not connected
  const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal })
  // check if already connected
  if (!connector.connected) {
    // create new session
    await connector.createSession()
  }
  if (connector.connected) {
    if (isNewInit) {
      await connector.killSession()
      app.ports.restartWalletConnectNotarization.send(address)
    } else {
      const { accounts } = connector
      await notarizePayment(
        app,
        accounts,
        address,
        connector,
        notarizationDetails,
        location,
        lastPayment,
      )
    }
  }

  connector.on('connect', async (error, payload) => {
    const { accounts } = payload.params[0]
    await notarizePayment(
      app,
      accounts,
      address,
      connector,
      notarizationDetails,
      location,
      lastPayment,
    )
  })
}

const notarizePayment = async (
  app: App,
  accounts: string[],
  address: string,
  connector: WalletConnect,
  notarizationDetails: NotarizationDetails,
  location: Location,
  lastPayment: boolean,
): Promise<void> => {
  try {
    if (accounts.every((account: string) => account !== address)) {
      // send port error
      await connector.killSession()
      return
    }
    const signTxn = await signTxnScenario(
      singleAssetTransferTxn,
      connector,
      chainType,
      address,
      notarizationDetails,
    )
    switch (location) {
      case 'ViewDoc':
        app.ports.notarizationSendViewDocStart.send('Start')
        break
      case 'UploadDoc':
        app.ports.notarizationSendUploadDocStart.send('Start')
        break
      case 'FillTemplate':
        app.ports.notarizationSendFillTemplateStart.send('Start')
        break
    }

    if (signTxn !== null) {
      const transactionData = await submitSignedTransaction(signTxn, chainType)
      if (typeof transactionData == 'string') {
        switch (location) {
          case 'ViewDoc':
            console.log('Null transaction Data')
            app.ports.walletConnectErrorViewDoc.send('Error')
            await connector.killSession()
            break
          case 'UploadDoc':
            console.log('Null transaction Data')
            app.ports.sendPaymentTransactionErrorUploadRoute.send({
              error: transactionData,
            })
            break
        }
        return
      }
      switch (location) {
        case 'ViewDoc':
          app.ports.walletConnectNotarizationCompleted.send({
            timestamp: Date.now(),
            address,
            ...transactionData,
          })
          break
        case 'UploadDoc':
          app.ports.sendNotarisationDetailsUploadRoute.send({
            transactionHash: transactionData.id,
            blockNumber: transactionData.block,
            notarisationTimeStamp: Date.now(),
          })
          break
        case 'FillTemplate':
          app.ports.sendNotarisationPaymentFillTemplateRoute.send({
            transactionHash: transactionData.id,
            blockNumber: transactionData.block,
            notarisationTimeStamp: Date.now(),
          })
          break
        default:
          return
      }
    }
  } catch (error) {
    switch (location) {
      case 'ViewDoc':
        console.log('error: ', error)
        app.ports.walletConnectErrorViewDoc.send('Error')
        await connector.killSession()
        break
      case 'UploadDoc':
        console.log('error: ', error)
        app.ports.sendPaymentTransactionErrorUploadRoute.send({ error })
        break
    }
  }
  if (lastPayment) {
    await connector.killSession()
  }
}

const walletConnectoptIn = (app: App) => async (data: {
  address: string
  location: Location
}): Promise<void> => {
  const { address, location } = data
  const bridge = 'https://bridge.walletconnect.org'
  // create new connector and flashes QR if not connected
  const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal })
  // check if already connected
  if (!connector.connected) {
    // create new session
    await connector.createSession()
  }
  if (connector.accounts.every((account) => account !== address)) {
    app.ports.sendOptInErrorUploadRoute.send('error')
    return
  }
  try {
    const signTxn = await signTxnScenario(
      singleAssetOptInTxn,
      connector,
      chainType,
      address,
      null,
    )
    if (signTxn !== null) {
      const transactionData = await submitSignedTransaction(signTxn, chainType)
      if (typeof transactionData == 'string') {
        switch (location) {
          case 'UploadDoc':
            app.ports.sendOptInErrorUploadRoute.send('error')
            break
          // Add one for view and points
        }
        // Add Error port here
        return
      }
      switch (location) {
        case 'Points':
          app.ports.walletConnectOptInCompleted.send({
            address,
            ...transactionData,
          })
          await connector.killSession()
          break
        case 'ViewDoc':
          app.ports.walletConnectOptInCompletedViewDoc.send({
            address,
            ...transactionData,
          })
          break
        case 'UploadDoc':
          app.ports.walletConnectOptInCompletedUploadDoc.send({
            address,
            ...transactionData,
          })
          break
        case 'FillTemplate':
          app.ports.walletConnectOptInCompletedFillTemplate.send({
            address,
            ...transactionData,
          })
          break
      }
    }
  } catch (error) {
    switch (location) {
      case 'UploadDoc':
        app.ports.sendOptInErrorUploadRoute.send('error')
        break
      // Add one for view and points
    }
  }

  // Add error sending for empty transaction
}
const walletConnectInit = (app: App) => async (
  location: Location,
): Promise<void> => {
  // bridge url
  const bridge = 'https://bridge.walletconnect.org'
  // create new connector and flashes QR if not connected
  const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal })
  // check if already connected
  if (!connector.connected) {
    // create new session
    await connector.createSession()
  }
  subscribeToEvents(connector, app, location)
}

function subscribeToEvents(
  connector: WalletConnect,
  app: App,
  location: Location,
) {
  if (!connector) {
    return
  }

  connector.on('session_update', async (error, payload) => {
    if (error) {
      throw error
    }
  })

  connector.on('connect', async (error, payload) => {
    switch (location) {
      case 'ViewDoc':
        app.ports.notarizationSendViewDocStart.send('Start')
        break
      case 'UploadDoc':
        app.ports.optInUploadDocStart.send('Start')
        break
      case 'FillTemplate':
        app.ports.optInFillTemplateStart.send('Start')
        break
    }
    if (error) {
      throw error
    }
    const { accounts } = payload.params[0]
    const accountList = await Promise.all(
      accounts.map(async (account: string) => {
        const accountData = await apiGetAccountAssets(chainType, account)
        const needOptIn = accountData.every(
          (asset) => asset.id !== signAssetIndex,
        )
        return { address: account, needOptIn }
      }),
    )
    switch (location) {
      case 'Points':
        app.ports.sendWalletConnectAddresses.send(accountList)
        break
      case 'ViewDoc':
        app.ports.sendWalletConnectAddressesViewDoc.send(accountList)
        break
      case 'UploadDoc':
        app.ports.sendWalletConnectAddressesUploadDoc.send(accountList)
        break
      case 'FillTemplate':
        app.ports.sendWalletConnectAddressesFillTemplate.send(accountList)
        break
    }
  })

  connector.on('disconnect', (error, payload) => {
    if (error) {
      throw error
    }
  })

  // if a connection is found, all parameteres are updated
  if (connector.connected) {
    connector.killSession()
    switch (location) {
      case 'Points':
        app.ports.restartWalletConnect.send('Restart')
        break
      case 'ViewDoc':
        app.ports.restartWalletConnectViewDoc.send('Restart')
    }

    // Add Retry session
  }
}

// infinite
const singleAssetOptInTxn: Scenario = async (
  chain: ChainType,
  address: string,
  data: NotarizationDetails | null,
): Promise<ScenarioReturnType> => {
  // getting chain related parameters for the transaction from algorand's chain (testnet OR mainnet)
  const suggestedParams = await apiGetTxnParams(chain)
  // creating a transaction with the relevant details (including the chain related parameters)
  const encodedNote = algosdk.encodeObj({ note: 'Opt in to SIGN points ASA' })
  const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: address,
    to: address,
    amount: 0,
    assetIndex: signAssetIndex,
    suggestedParams,
    note: encodedNote,
  })
  // returning the transaction to sign in an array, possibly for multi-signatory purposes
  const txnsToSign = [{ txn, shouldSign: true }]
  return [txnsToSign]
}

const singleAssetTransferTxn: Scenario = async (
  chain: ChainType,
  address: string,
  data: NotarizationDetails | null,
): Promise<ScenarioReturnType> => {
  // getting chain related parameters for the transaction from algorand's chain (testnet OR mainnet)
  if (data == null) return []
  const suggestedParams = await apiGetTxnParams(chain)
  // TODO please change this to dynamically reflect the correct checksum and IDs
  const notarizationDetails = {
    CheckSum: data.checksum, // this is the document checksum
    id: data.docId, // this is the document id
    ownerId: data.ownerId, // this is the sender's UUID
    recipients:
      data.recipients.length == 0 ? '[ ]' : data.recipients.toString(), // this is an array of UUID of all parties receiving the document
  }
  const encodedNote = algosdk.encodeObj(
    data.checksum == null
      ? { note: 'Payment for Notarization for document ' + data.docId } //To be confirmed
      : notarizationDetails,
  )
  // creating a transaction with the relevant details (including the chain related parameters)
  const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: address,
    to: 'FS3O5XZLGB7SJGH6YNGVKSUPNT255QXXCEQMLP3QEAQILACKPGG7MIQGZI',
    amount: 50000000, // Need to switch back to 50000000
    assetIndex: signAssetIndex,
    note: encodedNote,
    suggestedParams,
  })

  // returning the transaction to sign in an array, possibly for multi-signatory purposes
  const txnsToSign = [{ txn, shouldSign: true }]
  return [txnsToSign]
}

// signTxnScenario takes in the various transaction scenarios as arguments and is triggered when the respective buttons are clicked.
async function signTxnScenario(
  scenario: Scenario,
  connector: WalletConnect,
  chain: ChainType,
  address: string,
  transactionData: NotarizationDetails | null,
): Promise<Uint8Array[][] | null> {
  if (!connector) {
    return null
  }

  try {
    const txnsToSign = await scenario(chain, address, transactionData)

    const flatTxns = txnsToSign.reduce((acc, val) => acc.concat(val), [])

    const walletTxns: IWalletTransaction[] = flatTxns.map(
      ({ txn, signers, authAddr, message }) => ({
        txn: Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString(
          'base64',
        ),
        signers, // TODO: put auth addr in signers array
        authAddr,
        message,
      }),
    )

    // sign transaction
    const requestParams: SignTxnParams = [walletTxns]
    const request = formatJsonRpcRequest('algo_signTxn', requestParams)
    const result: Array<string | null> = await connector.sendCustomRequest(
      request,
    )
    const indexToGroup = (index: number) => {
      for (let group = 0; group < txnsToSign.length; group++) {
        const groupLength = txnsToSign[group].length
        if (index < groupLength) {
          return [group, index]
        }

        index -= groupLength
      }

      throw new Error(`Index too large for groups: ${index}`)
    }

    const signedPartialTxns: Array<Array<Uint8Array | null>> = txnsToSign.map(
      () => [],
    )
    result.forEach((r, i) => {
      const [group, groupIndex] = indexToGroup(i)
      const toSign = txnsToSign[group][groupIndex]

      if (r == null) {
        if (toSign.signers !== undefined && toSign.signers?.length < 1) {
          signedPartialTxns[group].push(null)
          return
        }
        throw new Error(
          `Transaction at index ${i}: was not signed when it should have been`,
        )
      }

      if (toSign.signers !== undefined && toSign.signers?.length < 1) {
        throw new Error(
          `Transaction at index ${i} was signed when it should not have been`,
        )
      }

      const rawSignedTxn = Buffer.from(r, 'base64')
      signedPartialTxns[group].push(new Uint8Array(rawSignedTxn))
    })

    const signedTxns: Uint8Array[][] = signedPartialTxns.map(
      (signedPartialTxnsInternal, group) => {
        return signedPartialTxnsInternal.map((stxn, groupIndex) => {
          if (stxn) {
            return stxn
          }

          return signTxnWithTestAccount(txnsToSign[group][groupIndex].txn)
        })
      },
    )

    return signedTxns
  } catch (error) {
    console.error(error)
    return null
  }
}
