import algosdk from 'algosdk'

export enum ChainType {
  MainNet = 'mainnet',
  TestNet = 'testnet',
}

const token = {
  'X-API-Key': 'jJzrnCokdR7osUFaMmN8h8pErWFt4nEC6NHPONC9',
}

const mainNetClient = new algosdk.Algodv2(
  token,
  'https://mainnet-algorand.api.purestake.io/ps2',
  '',
)
const testNetClient = new algosdk.Algodv2(
  token,
  'https://testnet-algorand.api.purestake.io/ps2',
  '',
)

const testAccounts = [
  algosdk.mnemonicToSecretKey(
    'sunset hawk bottom vote hip tape tonight liquid hello album width useful one confirm topple embody man all hurt obtain trumpet census relief abandon palm',
  ),
  algosdk.mnemonicToSecretKey(
    'sunset hawk bottom vote hip tape tonight liquid hello album width useful one confirm topple embody man all hurt obtain trumpet census relief abandon palm',
  ),
  algosdk.mnemonicToSecretKey(
    'sunset hawk bottom vote hip tape tonight liquid hello album width useful one confirm topple embody man all hurt obtain trumpet census relief abandon palm',
  ),
]

interface IAssetData {
  id: number
  amount: bigint
  creator: string
  frozen: boolean
  decimals: number
  name?: string
  unitName?: string
  url?: string
}

type TransactionData = {
  id: string
  block: number
}

export function clientForChain(chain: ChainType): algosdk.Algodv2 {
  switch (chain) {
    case ChainType.MainNet:
      return mainNetClient
    case ChainType.TestNet:
      return testNetClient
    default:
      throw new Error(`Unknown chain type: ${chain}`)
  }
}
export async function apiGetTxnParams(
  chain: ChainType,
): Promise<algosdk.SuggestedParams> {
  const params = await clientForChain(chain).getTransactionParams().do()
  return params
}

export function signTxnWithTestAccount(txn: algosdk.Transaction): Uint8Array {
  const sender = algosdk.encodeAddress(txn.from.publicKey)

  for (const testAccount of testAccounts) {
    if (testAccount.addr === sender) {
      return txn.signTxn(testAccount.sk)
    }
  }

  throw new Error(
    `Cannot sign transaction from unknown test account: ${sender}`,
  )
}

export async function apiSubmitTransactions(
  chain: ChainType,
  stxns: Uint8Array[],
): Promise<TransactionData> {
  const transactionData = await clientForChain(chain)
    .sendRawTransaction(stxns)
    .do()
  const { txId } = transactionData

  return {
    id: txId,
    block: await waitForTransaction(chain, txId),
  }
}
async function waitForTransaction(
  chain: ChainType,
  txId: string,
): Promise<number> {
  const client = clientForChain(chain)

  let lastStatus = await client.status().do()
  let lastRound = lastStatus['last-round']
  while (true) {
    const status = await client.pendingTransactionInformation(txId).do()
    if (status['pool-error']) {
      throw new Error(`Transaction Pool Error: ${status['pool-error']}`)
    }
    if (status['confirmed-round']) {
      return status['confirmed-round']
    }
    lastStatus = await client.statusAfterBlock(lastRound + 1).do()
    lastRound = lastStatus['last-round']
  }
}

export async function apiGetAccountAssets(
  chain: ChainType,
  address: string,
): Promise<IAssetData[]> {
  const client = clientForChain(chain)

  const accountInfo = await client
    .accountInformation(address)
    .setIntDecoding(algosdk.IntDecoding.BIGINT)
    .do()

  const algoBalance = accountInfo.amount as bigint
  const assetsFromRes: Array<{
    'asset-id': bigint
    amount: bigint
    creator: string
    frozen: boolean
  }> = accountInfo.assets

  const assets: IAssetData[] = assetsFromRes.map(
    ({ 'asset-id': id, amount, creator, frozen }) => ({
      // id: Number(id),
      id: parseInt(id.toString()),
      amount,
      creator,
      frozen,
      decimals: 0,
    }),
  )

  assets.sort((a, b) => a.id - b.id)

  await Promise.all(
    assets.map(async (asset) => {
      const { params } = await client.getAssetByID(asset.id).do()
      asset.name = params.name
      asset.unitName = params['unit-name']
      asset.url = params.url
      asset.decimals = params.decimals
    }),
  )

  assets.unshift({
    id: 0,
    amount: algoBalance,
    creator: '',
    frozen: false,
    decimals: 6,
    name: 'Algo',
    unitName: 'Algo',
  })

  return assets
}

export async function submitSignedTransaction(
  signedTxns: Uint8Array[][],
  chain: ChainType,
): Promise<TransactionData | string> {
  const transactionInfo = await Promise.all(
    signedTxns.map(async (signedTxn, index) => {
      try {
        const confirmedRound = await apiSubmitTransactions(chain, signedTxn)
        console.log(`Transaction confirmed at round ${confirmedRound}`)
        return confirmedRound
      } catch (err) {
        console.error(`Error submitting transaction at index ${index}:`, err)
        return err.message
      }
    }),
  )
  return transactionInfo[0]
}
