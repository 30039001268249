import { App } from '../Main.elm'
import SignaturePad from 'signature_pad'

let signature: SignaturePad | null = null

const loadSignaturePad = (): void => {
  const interval = setInterval(async () => {
    const canvas = document.querySelector('#signature-pad')
    if (canvas != null && canvas instanceof HTMLCanvasElement) {
      clearInterval(interval)
      signature = new SignaturePad(canvas)
    }
  }, 200)
}

const clearSignature = (): void => {
  if (signature != null) {
    signature.clear()
  }
}

const saveSignature = (app: App) => (): void => {
  if (signature != null) {
    const svgBase64 = signature.toDataURL()
    app.ports.sendSignatureData.send(svgBase64.split(',')[1])
  }
}

export function initialiseCanvasPorts(app: App): void {
  app.ports.loadSignature.subscribe(loadSignaturePad)
  app.ports.clearSignature.subscribe(clearSignature)
  app.ports.saveSignature.subscribe(saveSignature(app))
}
