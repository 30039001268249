import { App } from '../Main.elm'

/* Instructions to get the font 
  1. Go to fonts.google.com
  2. Select the style u desire
  3. Copy and paste the url from the @import portion onto your browser
  4. Copy the url from the src and paste into variable url
  5. Copy text on browser and paste into the style.innerHtml then remove the src url and replace with the base64Font variable
*/
const extractSignatureFromSvg = (app: App) => async (): Promise<void> => {
  const url =
    'https://fonts.gstatic.com/s/greatvibes/v8/RWmMoKWR9v4ksMfaWd_JN9XFiaQ.woff2'
  const response = await fetch(url).then((result) => result.blob())
  const base64Font = await blobToBase64(response)
  const svgHtmlElement = document.getElementById('textSignature')
  const svgTextElement = document.getElementById('textOnSignature')
  svgHtmlElement?.setAttribute('font-family', 'Great Vibes')
  const style = document.createElementNS('http://www.w3.org/2000/svg', 'style')
  style.type = 'text/css'
  style.innerHTML = `
      /* latin */
      @font-face {
        font-family: 'Great Vibes';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${base64Font}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
                  `
  svgHtmlElement?.appendChild(style)
  const svgImage =
    svgHtmlElement == null
      ? ''
      : new XMLSerializer().serializeToString(svgHtmlElement)
  const encodedData = window.btoa(svgImage)
  const textElementRect = svgTextElement?.getClientRects()
  const pngData = await convertSvg2Png(
    'data:image/svg+xml;base64,' + encodedData,
    textElementRect?.item(0)?.width,
  )

  app.ports.sendTextSignatureData.send(pngData.split(',')[1])
}

const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

const convertSvg2Png = async (
  src: string,
  width: number | undefined,
): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image()

    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = width == null ? img.width : width + 100
      canvas.height = img.height
      canvas.getContext('2d')?.drawImage(img, 0, 0)
      const image = new Image()
      image.src = canvas.toDataURL('image/png')
      resolve(image.src)
    }
    img.src = src
  })
}

export const initialiseTextSignaturePort = (app: App): void => {
  app.ports.extractSvgSignature.subscribe(extractSignatureFromSvg(app))
}
